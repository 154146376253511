import { configService } from '../services';
import { configConstants } from '../constants/config.constants';

const edit = (key, body) => {
    const request = () => ({
        type: configConstants.CONFIG_EDIT_REQUEST
    });

    const success = () => ({
        type: configConstants.CONFIG_EDIT_SUCCESS
    });

    const failure = () => ({
        type: configConstants.CONFIG_EDIT_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        configService.edit(key, body).then(
            config => {
                dispatch(success());

                resolve(config);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const find = key => {
    const request = () => ({
        type: configConstants.CONFIG_FIND_REQUEST
    });

    const success = () => ({
        type: configConstants.CONFIG_FIND_SUCCESS
    });

    const failure = () => ({
        type: configConstants.CONFIG_FIND_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return configService.find(key).then(
            config => {
                dispatch(success());

                return config;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

export const configActions = {
    edit,
    find
};
