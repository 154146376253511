import axios from 'axios';

const getMenu = (type, name, isLoggedIn) => {
    const routeName = isLoggedIn
        ? '/menu-types/' + type + '/menus/' + name
        : '/open/menu-types/' + type + '/menus/' + name;

    return axios
        .get(routeName)
        .then(
            (response) => {
                const { menu } = response.data;

                return menu;
            },
            error => {
                const { response } = error;
                const { data } = response;
                const { errors } = data;

                return Promise.reject(errors);
            }
        );
};

export const menuServices = {
    getMenu
};
