export const categoryConstants = {
    CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
    CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
    CATEGORY_CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',

    CATEGORY_EDIT_REQUEST: 'CATEGORY_EDIT_REQUEST',
    CATEGORY_EDIT_SUCCESS: 'CATEGORY_EDIT_SUCCESS',
    CATEGORY_EDIT_FAILURE: 'CATEGORY_EDIT_FAILURE',

    CATEGORY_FIND_REQUEST: 'CATEGORY_FIND_REQUEST',
    CATEGORY_FIND_SUCCESS: 'CATEGORY_FIND_SUCCESS',
    CATEGORY_FIND_FAILURE: 'CATEGORY_FIND_FAILURE',

    CATEGORY_REMOVE_REQUEST: 'CATEGORY_REMOVE_REQUEST',
    CATEGORY_REMOVE_SUCCESS: 'CATEGORY_REMOVE_SUCCESS',
    CATEGORY_REMOVE_FAILURE: 'CATEGORY_REMOVE_FAILURE',

    CATEGORIES_GET_BY_PROVIDER_ID_REQUEST: 'CATEGORIES_GET_BY_PROVIDER_ID_REQUEST',
    CATEGORIES_GET_BY_PROVIDER_ID_SUCCESS: 'CATEGORIES_GET_BY_PROVIDER_ID_SUCCESS',
    CATEGORIES_GET_BY_PROVIDER_ID_FAILURE: 'CATEGORIES_GET_BY_PROVIDER_ID_FAILURE',

    MIN_DISPLAY_ORDER: 1,
    MAX_DISPLAY_ORDER: 30
};
