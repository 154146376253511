import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    Form,
    DatePicker,
    Alert,
    Row,
    Col,
    Button
} from 'antd';

import { styles } from 'dood-react-components';

import { configConstants } from '../../constants/config.constants';
import { constants } from '../../constants/common.constants';

const { Item: FormItem, create, createFormField } = Form;
const { RangePicker } = DatePicker;

const MaintenanceForm = create({
    name: 'maintenance',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        const { config } = props;

        return {
            [configConstants.KEY_MAINTENANCE_PERIOD]: createFormField(config[configConstants.KEY_MAINTENANCE_PERIOD])
        };
    }
})(({ form, translate, onConfigSave, onConfigReset, config, onCancel, error }) => {
    const { getFieldDecorator } = form;
    const { formItemLayoutWithoutLabel, columnLayout } = styles;

    const handleConfigSave = key => {
        const { validateFields } = form;

        validateFields([key], errors => {
            if (!errors) {
                onConfigSave(key);
            }
        });
    };

    const renderInfo = () => {
        const intervals = config[configConstants.KEY_MAINTENANCE_PERIOD].value;

        if (intervals.length === 0) {
            return translate('message.no_maintenance_period');
        }

        return `${translate('message.maintenance_period')} ${intervals[0].format(constants.DATE_TIME_FORMAT)} - ${intervals[1].format(constants.DATE_TIME_FORMAT)}`;
    };

    return (
        <Form {...formItemLayoutWithoutLabel}>
            {error && <Alert message={error} type='error' />}

            <FormItem>
                <Row gutter={16} style={{ marginBottom: 20 }}>
                    <Col {...columnLayout}>
                        {getFieldDecorator([configConstants.KEY_MAINTENANCE_PERIOD])(
                            <RangePicker
                                style={{ width: '100%' }}
                                showTime={{
                                    hideDisabledOptions: true,
                                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')]
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        )}
                    </Col>
                    <Col {...columnLayout}>
                        <Button
                            type='primary'
                            onClick={() => handleConfigSave(configConstants.KEY_MAINTENANCE_PERIOD)}
                            style={{ marginRight: 10 }}
                        >
                            {translate('button.save')}
                        </Button>
                        <Button
                            type='primary'
                            onClick={() => onConfigReset(configConstants.KEY_MAINTENANCE_PERIOD)}
                            disabled={config[configConstants.KEY_MAINTENANCE_PERIOD].value.length === 0}
                        >
                            {translate('button.reset')}
                        </Button>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col {...columnLayout}>
                        <Alert
                            message={translate('title.maintenance_period')}
                            description={renderInfo()}
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            </FormItem>
        </Form>
    );
});

MaintenanceForm.propTypes = {
    translate: PropTypes.func.isRequired,
    config: PropTypes.shape({
        [configConstants.KEY_MAINTENANCE_PERIOD]: PropTypes.shape({
            value: PropTypes.array
        })
    }),
    onConfigSave: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    error: PropTypes.string
};

MaintenanceForm.defaultProps = {
    config: {},
    error: ''
};

export default MaintenanceForm;
