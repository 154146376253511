import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Form, Input } from 'antd';

const OrderGiftCardFormItems = ({ giftCard, translate }) => {
    const { Item: FormItem } = Form;

    return (
        <div>
            <Divider>{translate('title.gift_card')}</Divider>

            <FormItem label={translate('label.code')}>
                <Input
                    placeholder={translate('label.code')}
                    value={giftCard.code}
                    disabled={true}
                />
            </FormItem>

            <FormItem label={translate('label.amount')}>
                <Input
                    placeholder={translate('label.amount')}
                    value={giftCard.amount}
                    disabled={true}
                />
            </FormItem>
        </div>
    );
};

OrderGiftCardFormItems.propTypes = {
    translate: PropTypes.func.isRequired,
    giftCard: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired
    }).isRequired
};

OrderGiftCardFormItems.defaultProps = {
    translate: key => (key)
};

export default OrderGiftCardFormItems;
