import axios from 'axios';

const table = body => {
    return axios.post('/category-tables', body).then(
        response => {
            const { table } = response.data;

            return table;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

const create = body => {
    return axios.post('/categories', body).then(
        response => {
            const { category } = response.data;

            return category;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const edit = (id, body) => {
    return axios.put('/categories/' + id, body).then(
        response => {
            const { category } = response.data;

            return category;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const find = id => {
    return axios.get('/categories/' + id).then(
        response => {
            const { category } = response.data;

            return category;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

const remove = id => {
    return axios.delete('/categories/' + id).then(
        () => {},
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const getByProviderId = providerId => {
    return axios.get('/categories', { params: { providerId } }).then(
        response => {
            const { categories } = response.data;

            return categories;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

export const categoryService = {
    table,
    getByProviderId,
    create,
    edit,
    find,
    remove
};
