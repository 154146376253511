import axios from 'axios';

const get = () => {
    return axios.get('/providers').then(
        response => {
            const { providers } = response.data;

            return providers;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

export const providerService = {
    get
};
