import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Form, Input } from 'antd';

const OrderProductFormItems = ({ translate, product }) => {
    const { Item: FormItem } = Form;

    return (
        <div>
            <Divider>{translate('label.product')}</Divider>

            <FormItem label={translate('label.provider')}>
                <Input
                    placeholder={translate('label.provider')}
                    value={product.category.provider.name}
                    disabled={true}
                />
            </FormItem>

            <FormItem label={translate('label.category')}>
                <Input
                    placeholder={translate('label.category')}
                    value={product.category.titleRo}
                    disabled={true}
                />
            </FormItem>

            <FormItem label={translate('label.product')}>
                <Input
                    placeholder={translate('label.product')}
                    value={product.titleRo}
                    disabled={true}
                />
            </FormItem>
        </div>
    );
};

OrderProductFormItems.propTypes = {
    translate: PropTypes.func.isRequired,
    product: PropTypes.shape({
        titleRo: PropTypes.string.isRequired,
        category: PropTypes.shape({
            provider: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            titleRo: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

OrderProductFormItems.defaultProps = {
    translate: key => (key)
};

export default OrderProductFormItems;
