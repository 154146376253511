import axios from 'axios';

const table = body => {
    return axios.post('/product-tables', body).then(
        response => {
            const { table } = response.data;

            return table;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

const create = body => {
    return axios.post('/products', body).then(
        response => {
            const { product } = response.data;

            return product;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const edit = (id, body) => {
    return axios.put('/products/' + id, body).then(
        response => {
            const { product } = response.data;

            return product;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const find = id => {
    return axios.get('/products/' + id).then(
        response => {
            const { product } = response.data;

            return product;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

const remove = id => {
    return axios.delete('/products/' + id).then(
        () => {},
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

export const productServices = {
    table,
    create,
    edit,
    find,
    remove
};
