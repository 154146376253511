import axios from 'axios';

const edit = (key, body) => {
    return axios.put('/configs/' + key, body).then(
        response => {
            const { config } = response.data;

            return config;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const find = key => {
    return axios.get('/configs/' + key).then(
        response => {
            const { config } = response.data;

            return config;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

export const configService = {
    edit,
    find
};
