import { postServices } from '../services';
import { postConstants } from '../constants/post.constants';

const table = body => {
    return async dispatch => {
        return postServices.table(body).then(
            table => {
                return table;
            },
            error => {
                return error;
            }
        );
    };
};

const create = body => {
    const request = () => ({
        type: postConstants.POST_CREATE_REQUEST
    });

    const success = () => ({
        type: postConstants.POST_CREATE_SUCCESS
    });

    const failure = () => ({
        type: postConstants.POST_CREATE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        postServices.create(body).then(
            post => {
                dispatch(success());

                resolve(post);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const edit = (id, body) => {
    const request = () => ({
        type: postConstants.POST_EDIT_REQUEST
    });

    const success = () => ({
        type: postConstants.POST_EDIT_SUCCESS
    });

    const failure = () => ({
        type: postConstants.POST_EDIT_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        postServices.edit(id, body).then(
            post => {
                dispatch(success());

                resolve(post);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const find = id => {
    const request = () => ({
        type: postConstants.POST_FIND_REQUEST
    });

    const success = () => ({
        type: postConstants.POST_FIND_SUCCESS
    });

    const failure = () => ({
        type: postConstants.POST_FIND_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return postServices.find(id).then(
            post => {
                dispatch(success());

                return post;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

const remove = id => {
    const request = () => ({
        type: postConstants.POST_REMOVE_REQUEST
    });

    const success = () => ({
        type: postConstants.POST_REMOVE_SUCCESS
    });

    const failure = () => ({
        type: postConstants.POST_REMOVE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        postServices.remove(id).then(
            () => {
                dispatch(success());

                resolve();
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

export const postActions = {
    table,
    create,
    edit,
    find,
    remove
};
