export const newsConstants = {
    NEWS_CREATE_REQUEST: 'NEWS_CREATE_REQUEST',
    NEWS_CREATE_SUCCESS: 'NEWS_CREATE_SUCCESS',
    NEWS_CREATE_FAILURE: 'NEWS_CREATE_FAILURE',

    NEWS_EDIT_REQUEST: 'NEWS_EDIT_REQUEST',
    NEWS_EDIT_SUCCESS: 'NEWS_EDIT_SUCCESS',
    NEWS_EDIT_FAILURE: 'NEWS_EDIT_FAILURE',

    NEWS_FIND_REQUEST: 'NEWS_FIND_REQUEST',
    NEWS_FIND_SUCCESS: 'NEWS_FIND_SUCCESS',
    NEWS_FIND_FAILURE: 'NEWS_FIND_FAILURE',

    NEWS_REMOVE_REQUEST: 'NEWS_REMOVE_REQUEST',
    NEWS_REMOVE_SUCCESS: 'NEWS_REMOVE_SUCCESS',
    NEWS_REMOVE_FAILURE: 'NEWS_REMOVE_FAILURE',

    UPLOAD_PATH: '/news/upload'
};

