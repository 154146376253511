export const configConstants = {
    CONFIG_EDIT_REQUEST: 'CONFIG_EDIT_REQUEST',
    CONFIG_EDIT_SUCCESS: 'CONFIG_EDIT_SUCCESS',
    CONFIG_EDIT_FAILURE: 'CONFIG_EDIT_FAILURE',

    CONFIG_FIND_REQUEST: 'CONFIG_FIND_REQUEST',
    CONFIG_FIND_SUCCESS: 'CONFIG_FIND_SUCCESS',
    CONFIG_FIND_FAILURE: 'CONFIG_FIND_FAILURE',

    KEY_DISCOUNT_INCARCA_RO: 'discount-incarca-ro',
    KEY_DISCOUNT_REINCARCARE_RO: 'discount-re-incarcare-ro',
    KEY_MAINTENANCE_PERIOD: 'maintenance-period'
};
