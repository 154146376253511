import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Tabs } from 'antd';
import { FormContainer, formHelper } from 'dood-react-components';

import DiscountsForm from '../../components/Form/DiscountsForm';
import MaintenanceForm from '../../components/Form/MaintenanceForm';
import ChangePasswordForm from '../../components/Form/ChangePasswordForm';

import { translator, createLoadingSelector } from '../../helpers';
import { configActions, authenticationActions } from '../../actions';
import { loadingConstants } from '../../constants/loading.constants';
import { configConstants } from '../../constants/config.constants';
import { constants } from '../../constants/common.constants';

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: formHelper.createStateFromFields([
                configConstants.KEY_DISCOUNT_INCARCA_RO,
                configConstants.KEY_DISCOUNT_REINCARCARE_RO,
                configConstants.KEY_MAINTENANCE_PERIOD
            ], {
                [configConstants.KEY_DISCOUNT_INCARCA_RO]: 0,
                [configConstants.KEY_DISCOUNT_REINCARCARE_RO]: 0,
                [configConstants.KEY_MAINTENANCE_PERIOD]: []
            }),
            error: '',
            password: formHelper.createStateFromFields(['password'])
        };
    }

    componentDidMount() {
        this.props.find(configConstants.KEY_DISCOUNT_INCARCA_RO).then(
            result => {
                this.setState(prevState => ({
                    ...prevState,
                    config: {
                        ...prevState.config,
                        [configConstants.KEY_DISCOUNT_INCARCA_RO]: {
                            value: result.value
                        }
                    }
                }));
            }
        );

        this.props.find(configConstants.KEY_DISCOUNT_REINCARCARE_RO).then(
            result => {
                this.setState(prevState => ({
                    ...prevState,
                    config: {
                        ...prevState.config,
                        [configConstants.KEY_DISCOUNT_REINCARCARE_RO]: {
                            value: result.value
                        }
                    }
                }));
            }
        );

        this.props.find(configConstants.KEY_MAINTENANCE_PERIOD).then(
            result => {
                result = JSON.parse(result.value);
                const value = !Array.isArray(result) ? [moment(result.startDate), moment(result.endDate)] : [];

                this.setState(prevState => ({
                    ...prevState,
                    config: {
                        ...prevState.config,
                        [configConstants.KEY_MAINTENANCE_PERIOD]: {
                            value: value
                        }
                    }
                }));
            }
        );
    }

    handleSave = key => {
        const { config } = this.state;

        delete config[key].errors;

        let data = Object.assign({}, config[key]);

        if (key === configConstants.KEY_MAINTENANCE_PERIOD) {
            const dates = config[key].value.length > 0
                ? { startDate: config[key].value[0].format(constants.DATE_TIME_FORMAT), endDate: config[key].value[1].format(constants.DATE_TIME_FORMAT) }
                : [];

            data.value = JSON.stringify(dates);
        }

        this.props.edit(key, data).then(
            () => { },
            errors => {
                const { config } = this.state;

                const { error } = formHelper.mapErrorsToFields(config, errors);

                this.setState({ error });
            }
        );
    };

    handleReset = key => {
        this.setState(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                [key]: { value: [] }
            }
        }), () => {
            this.handleSave(key);
        });
    };

    handleInputChange = changedFields => {
        this.setState(prevState => ({
            ...prevState,
            config: {
                ...prevState.config,
                ...changedFields
            }
        }));
    };

    handleChangePasswordInput = password => {
        this.setState(prevState => ({
            ...prevState,
            password: {
                ...prevState.password,
                ...password
            }
        }));
    };

    handleChangePassword = () => {
        this.props.changePassword({ password: this.state.password.password.value }).then(
            () => { },
            errors => {
                const { config } = this.state;
                const { error } = formHelper.mapErrorsToFields(config, errors);

                this.setState({ error });
            }
        );
    };

    render() {
        const { TabPane } = Tabs;

        const discountsformComponent = (
            <DiscountsForm
                translate={this.props.translate}
                config={this.state.config}
                onInputChange={this.handleInputChange}
                onConfigSave={this.handleSave}
                error={this.state.error}
            />
        );

        const maintenanceformComponent = (
            <MaintenanceForm
                translate={this.props.translate}
                config={this.state.config}
                onInputChange={this.handleInputChange}
                onConfigSave={this.handleSave}
                onConfigReset={this.handleReset}
                error={this.state.error}
            />
        );

        const changePasswordformComponent = (
            <ChangePasswordForm
                translate={this.props.translate}
                password={this.state.password}
                onInputChange={this.handleChangePasswordInput}
                onSubmit={this.handleChangePassword}
                error={this.state.error}
            />
        );

        return (
            <Tabs defaultActiveKey="2">
                <TabPane tab={this.props.translate('tab.discounts')} key="1">
                    <FormContainer
                        title={this.props.translate('title.settings')}
                        loading={this.props.loading}
                        formComponent={discountsformComponent}
                    />
                </TabPane>
                <TabPane tab={this.props.translate('tab.maintenance')} key="2">
                    <FormContainer
                        title={this.props.translate('title.settings')}
                        loading={this.props.loading}
                        formComponent={maintenanceformComponent}
                    />
                </TabPane>
                <TabPane tab={this.props.translate('label.password')} key="3">
                    <FormContainer
                        title={this.props.translate('label.password')}
                        loading={this.props.loading}
                        formComponent={changePasswordformComponent}
                    />
                </TabPane>
            </Tabs>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: createLoadingSelector([
            loadingConstants.CONFIG_FIND,
            loadingConstants.CONFIG_EDIT,
            loadingConstants.CHANGE_PASSWORD
        ])(state),
        translate: translator(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        find: key => dispatch(configActions.find(key)),
        edit: (key, config) => dispatch(configActions.edit(key, config)),
        changePassword: password => dispatch(authenticationActions.changePassword(password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
