export const productConstants = {
    PRODUCT_CREATE_REQUEST: 'PRODUCT_CREATE_REQUEST',
    PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
    PRODUCT_CREATE_FAILURE: 'PRODUCT_CREATE_FAILURE',

    PRODUCT_EDIT_REQUEST: 'PRODUCT_EDIT_REQUEST',
    PRODUCT_EDIT_SUCCESS: 'PRODUCT_EDIT_SUCCESS',
    PRODUCT_EDIT_FAILURE: 'PRODUCT_EDIT_FAILURE',

    PRODUCT_FIND_REQUEST: 'PRODUCT_FIND_REQUEST',
    PRODUCT_FIND_SUCCESS: 'PRODUCT_FIND_SUCCESS',
    PRODUCT_FIND_FAILURE: 'PRODUCT_FIND_FAILURE',

    PRODUCT_REMOVE_REQUEST: 'PRODUCT_REMOVE_REQUEST',
    PRODUCT_REMOVE_SUCCESS: 'PRODUCT_REMOVE_SUCCESS',
    PRODUCT_REMOVE_FAILURE: 'PRODUCT_REMOVE_FAILURE',

    MIN_DISPLAY_ORDER: 1,
    MAX_DISPLAY_ORDER: 400
};
