import { authenticationServices } from '../services';
import { authenticationConstants } from '../constants/authentication.constants';

const login = (username, password) => {
    const request = () => ({
        type: authenticationConstants.AUTHENTICATION_LOGIN_REQUEST
    });

    const success = user => ({
        type: authenticationConstants.AUTHENTICATION_LOGIN_SUCCESS,
        user
    });

    const failure = error => ({
        type: authenticationConstants.AUTHENTICATION_LOGIN_FAILURE,
        error
    });

    return dispatch => {
        dispatch(request());

        return authenticationServices.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
};

const logout = () => {
    const request = () => ({
        type: authenticationConstants.AUTHENTICATION_LOGOUT_REQUEST
    });

    const success = () => ({
        type: authenticationConstants.AUTHENTICATION_LOGOUT_SUCCESS
    });

    const failure = error => ({
        type: authenticationConstants.AUTHENTICATION_LOGOUT_FAILURE,
        error
    });

    return dispatch => {
        dispatch(request());

        authenticationServices.logout()
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
};

const me = () => {
    const request = () => ({
        type: authenticationConstants.AUTHENTICATION_STATUS_REQUEST
    });

    const success = () => ({
        type: authenticationConstants.AUTHENTICATION_STATUS_SUCCESS
    });

    const failure = error => ({
        type: authenticationConstants.AUTHENTICATION_STATUS_FAILURE,
        error
    });

    return dispatch => {
        dispatch(request());

        authenticationServices.me()
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
};

const changePassword = password => {
    const request = () => ({
        type: authenticationConstants.CHANGE_PASSWORD_REQUEST
    });

    const success = () => ({
        type: authenticationConstants.CHANGE_PASSWORD_SUCCESS
    });

    const failure = () => ({
        type: authenticationConstants.CHANGE_PASSWORD_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        authenticationServices.changePassword(password).then(
            () => {
                dispatch(success());

                resolve();
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

export const authenticationActions = {
    login,
    logout,
    me,
    changePassword
};
