import React from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    Alert,
    Icon,
    Input,
    Select,
    Button,
    Col,
    Row
} from 'antd';

import OrderProductFormItems from './OrderProductFormItems';
import OrderGiftCardFormItems from './OrderGiftCardFormItems';

import { InputValidationRegexps as DoodInputValidationRegexps, formHelper, styles } from 'dood-react-components';
import { InputValidationRegexps } from '../../constants/regexps.constants';

import { clientTypes, statuses, CLIENT_TYPE_PF, CLIENT_TYPE_PJ, STATUS_SUCCESS, STATUS_FAIL, STATUS_PENDING } from '../../constants/order.constants';

const { Item: FormItem, create } = Form;

const OrderForm = create({
    name: 'order',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return formHelper.mapPropsToFields(props.order, props.fields);
    }
})(({ order, form, translate, product, giftCard, onSubmit, onCancel, onClientTypeChange, onIssueProduct, onIssueInvoice, error }) => {
    const { getFieldDecorator, getFieldValue } = form;
    const { formItemLayout, buttonsLayout } = styles;

    const type = getFieldValue('clientType');

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <FormItem label={translate('label.first_name')}>
                {getFieldDecorator('firstName', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.first_name')
                    }]
                })(
                    <Input placeholder={translate('label.first_name')} />
                )}
            </FormItem>

            <FormItem label={translate('label.last_name')}>
                {getFieldDecorator('lastName', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.last_name')
                    }]
                })(
                    <Input placeholder={translate('label.last_name')} />
                )}
            </FormItem>

            <FormItem label={translate('label.phone')}>
                {getFieldDecorator('phone', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.phone')
                    }, {
                        pattern: InputValidationRegexps.INPUT_VALIDATION_REGEX_ROMANIAN_PHONE,
                        message: translate('validation.format.phone')
                    }]
                })(
                    <Input placeholder={translate('label.phone')} />
                )}
            </FormItem>

            <FormItem label={translate('label.email')}>
                {getFieldDecorator('email', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.email')
                    }, {
                        pattern: DoodInputValidationRegexps.INPUT_VALIDATION_REGEX_EMAIL,
                        message: translate('validation.format.email')
                    }]
                })(
                    <Input placeholder={translate('label.email')} />
                )}
            </FormItem>

            <FormItem label={translate('label.type')}>
                {getFieldDecorator('clientType', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.client_type')
                    }]
                })(
                    <Select placeholder={translate('label.type')} onChange={onClientTypeChange}>
                        {formHelper.renderOptions(clientTypes, 'type', 'type')}
                    </Select>
                )}
            </FormItem>

            <FormItem label={translate('label.company')}>
                {getFieldDecorator('company', {
                    rules: [{
                        required: type === CLIENT_TYPE_PJ,
                        message: translate('validation.required.company')
                    }]
                })(
                    <Input placeholder={translate('label.company')} disabled={type === CLIENT_TYPE_PF} />
                )}
            </FormItem>

            <FormItem label={translate('label.cui')}>
                {getFieldDecorator('cui', {
                    rules: [{
                        required: type === CLIENT_TYPE_PJ,
                        message: translate('validation.required.cui')
                    }]
                })(
                    <Input placeholder={translate('label.cui')} disabled={type === CLIENT_TYPE_PF} />
                )}
            </FormItem>

            <FormItem label={translate('label.price')}>
                {getFieldDecorator('price')(
                    <Input placeholder={translate('label.price')} disabled={true} />
                )}
            </FormItem>

            <FormItem label={translate('label.discount')}>
                {getFieldDecorator('discount')(
                    <Input placeholder={translate('label.discount')} disabled={true} />
                )}
            </FormItem>

            <FormItem label={translate('label.status_payu')}>
                {getFieldDecorator('statusPayu')(
                    <Select disabled={true}>
                        {formHelper.renderOptions(statuses, 'value', 'text', translate)}
                    </Select>
                )}
            </FormItem>

            <FormItem label={translate('label.status_fgo')}>
                <Row gutter={8}>
                    <Col span={16}>
                        {getFieldDecorator('statusFgo')(
                            <Select disabled={true}>
                                {formHelper.renderOptions(statuses, 'value', 'text', translate)}
                            </Select>
                        )}
                    </Col>
                    <Col span={8}>
                        {
                            getFieldValue('statusPayu') === STATUS_SUCCESS &&
                            (getFieldValue('statusFgo') === STATUS_FAIL || getFieldValue('statusFgo') === STATUS_PENDING) &&
                            <Button onClick={onIssueInvoice}>{translate('button.issue_invoice')}</Button>
                        }
                    </Col>
                </Row>
            </FormItem>

            <FormItem label={translate('label.status_api')}>
                <Row gutter={8}>
                    <Col span={16}>
                        {getFieldDecorator('statusMobile')(
                            <Select disabled={true}>
                                {formHelper.renderOptions(statuses, 'value', 'text', translate)}
                            </Select>
                        )}
                    </Col>
                    <Col span={8}>
                        {
                            getFieldValue('statusPayu') === STATUS_SUCCESS &&
                            getFieldValue('statusMobile') === STATUS_FAIL &&
                            <Button onClick={onIssueProduct}>{translate('button.issue_product')}</Button>
                        }
                    </Col>
                </Row>
            </FormItem>

            <FormItem label={translate('label.invoice')}>
                <Row gutter={8}>
                    <Col span={16}>
                        {getFieldDecorator('invoiceNumber')(
                            <Input placeholder={translate('label.invoice')} disabled={true} />
                        )}
                    </Col>
                    <Col span={8}>
                        {
                            getFieldValue('statusFgo') === STATUS_SUCCESS &&
                            <a href={order.invoiceUrl} target='_blank' rel='noopener noreferrer'>
                                <Icon type='download' />
                            </a>
                        }
                    </Col>
                </Row>
            </FormItem>

            <FormItem label={translate('label.pin')}>
                {getFieldDecorator('pin')(
                    <Input placeholder={translate('label.pin')} disabled={true} />
                )}
            </FormItem>

            {product && <OrderProductFormItems product={product} translate={translate} />}

            {giftCard && <OrderGiftCardFormItems giftCard={giftCard} translate={translate} />}

            <FormItem {...buttonsLayout}>
                <Button type='primary' htmlType='submit'>
                    {translate('button.save')}
                </Button>

                <Button htmlType='button' style={{ marginLeft: 15 }} onClick={onCancel}>
                    {translate('button.cancel')}
                </Button>
            </FormItem>
        </Form>
    );
});

OrderForm.propTypes = {
    ...formHelper.propTypes,
    translate: PropTypes.func.isRequired,
    order: PropTypes.shape({
        firstName: PropTypes.shape({
            value: PropTypes.string
        }),
        lastName: PropTypes.shape({
            value: PropTypes.string
        }),
        phone: PropTypes.shape({
            value: PropTypes.string
        }),
        email: PropTypes.shape({
            value: PropTypes.string
        }),
        clientType: PropTypes.shape({
            value: PropTypes.string
        }),
        company: PropTypes.shape({
            value: PropTypes.string
        }),
        cui: PropTypes.shape({
            value: PropTypes.string
        }),
        statusPayu: PropTypes.shape({
            value: PropTypes.number
        }),
        statusMobile: PropTypes.shape({
            value: PropTypes.number
        }),
        statusFgo: PropTypes.shape({
            value: PropTypes.number
        }),
        invoiceNumber: PropTypes.shape({
            value: PropTypes.string
        }),
        pin: PropTypes.shape({
            value: PropTypes.string
        })
    }),
    product: PropTypes.shape({
        titleRo: PropTypes.string,
        category: PropTypes.shape({
            titleRo: PropTypes.string,
            provider: PropTypes.shape({
                name: PropTypes.string
            })
        })
    }),
    giftCard: PropTypes.shape({
        amount: PropTypes.string,
        code: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onClientTypeChange: PropTypes.func.isRequired,
    onIssueProduct: PropTypes.func.isRequired,
    onIssueInvoice: PropTypes.func.isRequired
};

OrderForm.defaultProps = formHelper.defaultProps;

export default OrderForm;
