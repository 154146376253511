import { newsServices } from '../services';
import { newsConstants } from '../constants/news.constants';

const table = body => {
    return async dispatch => {
        return newsServices.table(body).then(
            table => {
                return table;
            },
            error => {
                return error;
            }
        );
    };
};

const create = body => {
    const request = () => ({
        type: newsConstants.NEWS_CREATE_REQUEST
    });

    const success = () => ({
        type: newsConstants.NEWS_CREATE_SUCCESS
    });

    const failure = () => ({
        type: newsConstants.NEWS_CREATE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        newsServices.create(body).then(
            news => {
                dispatch(success());

                resolve(news);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const edit = (id, body) => {
    const request = () => ({
        type: newsConstants.NEWS_EDIT_REQUEST
    });

    const success = () => ({
        type: newsConstants.NEWS_EDIT_SUCCESS
    });

    const failure = () => ({
        type: newsConstants.NEWS_EDIT_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        newsServices.edit(id, body).then(
            news => {
                dispatch(success());

                resolve(news);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const find = id => {
    const request = () => ({
        type: newsConstants.NEWS_FIND_REQUEST
    });

    const success = () => ({
        type: newsConstants.NEWS_FIND_SUCCESS
    });

    const failure = () => ({
        type: newsConstants.NEWS_FIND_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return newsServices.find(id).then(
            news => {
                dispatch(success());

                return news;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

const remove = id => {
    const request = () => ({
        type: newsConstants.NEWS_REMOVE_REQUEST
    });

    const success = () => ({
        type: newsConstants.NEWS_REMOVE_SUCCESS
    });

    const failure = () => ({
        type: newsConstants.NEWS_REMOVE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        newsServices.remove(id).then(
            () => {
                dispatch(success());

                resolve();
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

export const newsActions = {
    table,
    create,
    edit,
    find,
    remove
};
