import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormContainer, formHelper } from 'dood-react-components';

import OrderForm from '../../components/Form/OrderForm';

import { createLoadingSelector, translator } from '../../helpers';
import { orderActions } from '../../actions';
import { loadingConstants } from '../../constants/loading.constants';

class Form extends Component {
    static fields = [
        'firstName',
        'lastName',
        'phone',
        'email',
        'clientType',
        'company',
        'cui',
        'statusPayu',
        'statusMobile',
        'statusFgo',
        'invoiceNumber',
        'pin',
        'price',
        'discount'
    ];

    constructor(props) {
        super(props);

        this.state = {
            order: formHelper.createStateFromFields(Form.fields),
            error: ''
        };
    }

    componentDidMount() {
        this.props.find(this.props.match.params.id).then(
            response => {
                const { product, giftCard, invoiceUrl } = response;

                let order = formHelper.mapValuesToFields(response, Form.fields);

                order.invoiceUrl = invoiceUrl;

                this.setState({ order, product, giftCard });
            }
        );
    }

    handleInputChange = changedFields => {
        this.setState(prevState => ({
            ...prevState,
            order: {
                ...prevState.order,
                ...changedFields
            }
        }));
    };

    handleSubmit = () => {
        const { order } = this.state;

        let data = {};

        Form.fields.forEach(field => {
            data[field] = order[field].value;

            // clear form errors
            delete order[field].errors;
        });

        this.setState({ order }, () => {
            this.props.edit(this.props.match.params.id, data).then(
                () => {
                    this.props.history.push('/orders');
                },
                errors => {
                    this.handleErrors(errors);
                }
            );
        });
    };

    handleCancel = () => {
        this.props.history.push('/orders');
    };

    handleClientTypeChange = () => {
        const { order } = this.state;

        ['company', 'cui'].forEach(field => {
            // clear form errors
            delete order[field].errors;
        });

        this.setState({ order });
    };

    handleIssueProduct = () => {
        this.props.issueProduct(this.props.match.params.id).then(
            response => {
                this.setState({
                    order: formHelper.mapValuesToFields(response, Form.fields),
                    error: ''
                });
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleIssueInvoice = () => {
        this.props.issueInvoice(this.props.match.params.id).then(
            response => {
                let order = formHelper.mapValuesToFields(response, Form.fields);

                order.invoiceUrl = response.invoiceUrl;

                this.setState({
                    order,
                    error: ''
                });
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleErrors = errors => {
        const { order } = this.state;

        const { error, object } = formHelper.mapErrorsToFields(order, errors);

        this.setState({ order: object, error });
    };

    render() {
        const formComponent = (
            <OrderForm
                translate={this.props.translate}
                order={this.state.order}
                product={this.state.product}
                giftCard={this.state.giftCard}
                onInputChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                onClientTypeChange={this.handleClientTypeChange}
                onIssueProduct={this.handleIssueProduct}
                onIssueInvoice={this.handleIssueInvoice}
                error={this.state.error}
                fields={Form.fields}
            />
        );

        return (
            <FormContainer
                title={this.props.translate('title.edit_order')}
                loading={this.props.loading}
                formComponent={formComponent}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state),
        loading: createLoadingSelector([
            loadingConstants.ORDER_FIND,
            loadingConstants.ORDER_EDIT,
            loadingConstants.ORDER_ISSUE_PRODUCT,
            loadingConstants.ORDER_ISSUE_INVOICE
        ])(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        find: id => dispatch(orderActions.find(id)),
        edit: (id, order) => dispatch(orderActions.edit(id, order)),
        issueProduct: id => dispatch(orderActions.issueProduct(id)),
        issueInvoice: id => dispatch(orderActions.issueInvoice(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
