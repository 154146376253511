import { menuConstants } from '../constants/menu.constants';

const initialState = { menu: {} };

export const menu = (state = initialState, action) => {
    switch (action.type) {
        case menuConstants.FETCH_MENU_SUCCESS:
            return {
                menu: action.menu
            };
        case menuConstants.FETCH_MENU_FAILURE:
            return {
                error: action.error
            };

        default:
            return state;
    }
};
