import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormContainer, formHelper } from 'dood-react-components';

import PostForm from '../../components/Form/PostForm';

import { createLoadingSelector, translator } from '../../helpers';
import { postActions, fileActions } from '../../actions';
import { loadingConstants } from '../../constants/loading.constants';
import { LANGUAGE_EN } from '../../constants/languages.constants';
import { postConstants } from '../../constants/post.constants';

class Form extends Component {
    static fields = [
        'title',
        'description',
        'metaTitle',
        'metaDescription',
        'slug',
        'language',
        'fileId',
        'origin'
    ];

    constructor(props) {
        super(props);

        this.state = {
            post: formHelper.createStateFromFields(Form.fields, {
                language: LANGUAGE_EN
            }),
            error: ''
        };
    }

    componentDidMount() {
        if (this.props.isEdit) {
            this.props.find(this.props.match.params.id).then(
                response => {
                    const post = formHelper.mapValuesToFields(response, Form.fields);

                    this.setState({ post });
                }
            );
        }
    }

    handleSubmit = () => {
        const { post } = this.state;

        let data = {};

        Form.fields.forEach(field => {
            data[field] = post[field].value;

            // clear form errors
            delete post[field].errors;
        });

        this.setState({ post }, () => {
            this.props.isEdit
                ? this.handleEdit(data)
                : this.handleCreate(data);
        });
    };

    handleCreate = data => {
        this.props.create(data).then(
            () => {
                this.props.history.push('/posts');
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleEdit = data => {
        this.props.edit(this.props.match.params.id, data).then(
            () => {
                this.props.history.push('/posts');
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleCancel = () => {
        this.props.history.push('/posts');
    };

    handleInputChange = changedFields => {
        this.setState(prevState => ({
            ...prevState,
            post: {
                ...prevState.post,
                ...changedFields
            }
        }));
    };

    handleErrors = errors => {
        const { post } = this.state;

        const { error, object } = formHelper.mapErrorsToFields(post, errors);

        this.setState({ post: object, error });
    };

    render() {
        const columnLayout = {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 }
        };

        const formComponent = (
            <PostForm
                isEdit={this.props.isEdit}
                translate={this.props.translate}
                post={this.state.post}
                onInputChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                upload={this.props.upload}
                error={this.state.error}
                fields={Form.fields}
            />
        );

        return (
            <FormContainer
                title={this.props.translate(this.props.isEdit ? 'title.edit_post' : 'title.create_post')}
                loading={this.props.loading}
                formComponent={formComponent}
                columnLayout={columnLayout}
            />
        );
    }
}

Form.propTypes = {
    isEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        translate: translator(state),
        loading: createLoadingSelector([
            loadingConstants.POST_CREATE,
            loadingConstants.POST_EDIT,
            loadingConstants.POST_FIND
        ])(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        find: id => dispatch(postActions.find(id)),
        create: post => dispatch(postActions.create(post)),
        edit: (id, post) => dispatch(postActions.edit(id, post)),
        upload: file => dispatch(fileActions.upload(file, postConstants.UPLOAD_PATH))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
