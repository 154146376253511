export const loadingConstants = {
    FETCH_MENU: 'FETCH_MENU',
    FETCH_TRANSLATIONS: 'FETCH_TRANSLATIONS',

    // authentication
    AUTHENTICATION_LOGIN: 'AUTHENTICATION_LOGIN',
    AUTHENTICATION_STATUS: 'AUTHENTICATION_STATUS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',

    // categories
    CATEGORY_CREATE: 'CATEGORY_CREATE',
    CATEGORY_EDIT: 'CATEGORY_EDIT',
    CATEGORY_FIND: 'CATEGORY_FIND',

    // currencies
    GET_CURRENCIES: 'GET_CURRENCIES',

    // providers
    GET_PROVIDERS: 'GET_PROVIDERS',

    // orders
    ORDER_FIND: 'ORDER_FIND',
    ORDER_EDIT: 'ORDER_EDIT',
    ORDER_ISSUE_PRODUCT: 'ORDER_ISSUE_PRODUCT',
    ORDER_ISSUE_INVOICE: 'ORDER_ISSUE_INVOICE',

    // products
    PRODUCT_CREATE: 'PRODUCT_CREATE',
    PRODUCT_EDIT: 'PRODUCT_EDIT',
    PRODUCT_FIND: 'PRODUCT_FIND',

    // config
    CONFIG_FIND: 'CONFIG_FIND',
    CONFIG_EDIT: 'CONFIG_EDIT',

    // exchange rates
    EXCHANGE_RATE_CREATE: 'EXCHANGE_RATE_CREATE',

    // dashboard
    DASHBOARD_STATS: 'DASHBOARD_STATS',

    // posts
    POST_CREATE: 'POST_CREATE',
    POST_EDIT: 'POST_EDIT',
    POST_FIND: 'POST_FIND',

    // news
    NEWS_CREATE: 'NEWS_CREATE',
    NEWS_EDIT: 'NEWS_EDIT',
    NEWS_FIND: 'NEWS_FIND'
};
