import axios from 'axios';

const dashboard = (start, end) => {
    return axios.get(`/stats/dashboard/${start}/${end}`).then(
        response => {
            const { stats } = response.data;

            return stats;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

export const statsService = {
    dashboard
};
