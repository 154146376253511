import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormContainer, formHelper } from 'dood-react-components';

import CategoryForm from '../../components/Form/CategoryForm';

import { translator, createLoadingSelector } from '../../helpers';
import { categoryActions, providerActions } from '../../actions';
import { loadingConstants } from '../../constants/loading.constants';
import { categoryConstants } from '../../constants/category.constants';

class Form extends Component {
    static fields = [
        'providerId',
        'displayOrder',
        'isActive',
        'titleRo',
        'descriptionRo',
        'titleEn',
        'descriptionEn',
        'titleHu',
        'descriptionHu'
    ];

    constructor(props) {
        super(props);

        this.state = {
            providers: [],
            category: formHelper.createStateFromFields(Form.fields, {
                displayOrder: categoryConstants.MIN_DISPLAY_ORDER,
                isActive: true
            }),
            error: ''
        };
    }

    componentDidMount() {
        this.props.getProviders().then(
            providers => {
                this.setState({ providers });
            }
        );

        if (this.props.isEdit) {
            this.props.find(this.props.match.params.id).then(
                response => {
                    const category = formHelper.mapValuesToFields(response, Form.fields);

                    this.setState({ category });
                }
            );
        }
    }

    handleSubmit = () => {
        const { category } = this.state;

        let data = {};

        Form.fields.forEach(field => {
            data[field] = category[field].value;

            // clear form errors
            delete category[field].errors;
        });

        this.setState({ category }, () => {
            this.props.isEdit
                ? this.handleEdit(data)
                : this.handleCreate(data);
        });
    };

    handleCreate = data => {
        this.props.create(data).then(
            () => {
                this.props.history.push('/categories');
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleEdit = data => {
        this.props.edit(this.props.match.params.id, data).then(
            () => {
                this.props.history.push('/categories');
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleErrors = errors => {
        const { category } = this.state;

        const { error, object } = formHelper.mapErrorsToFields(category, errors);

        this.setState({ category: object, error });
    };

    handleCancel = () => {
        this.props.history.push('/categories');
    };

    handleInputChange = changedFields => {
        this.setState(prevState => ({
            ...prevState,
            category: {
                ...prevState.category,
                ...changedFields
            }
        }));
    };

    render() {
        const formComponent = (
            <CategoryForm
                translate={this.props.translate}
                category={this.state.category}
                providers={this.state.providers}
                onInputChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                error={this.state.error}
                fields={Form.fields}
            />
        );

        return (
            <FormContainer
                title={this.props.translate(this.props.isEdit ? 'title.edit_category' : 'title.create_category')}
                loading={this.props.loading}
                formComponent={formComponent}
            />
        );
    }
}

Form.propTypes = {
    isEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        loading: createLoadingSelector([
            loadingConstants.GET_PROVIDERS,
            loadingConstants.CATEGORY_CREATE,
            loadingConstants.CATEGORY_FIND,
            loadingConstants.CATEGORY_EDIT
        ])(state),
        translate: translator(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProviders: () => dispatch(providerActions.get()),
        find: id => dispatch(categoryActions.find(id)),
        create: category => dispatch(categoryActions.create(category)),
        edit: (id, category) => dispatch(categoryActions.edit(id, category))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
