export const CLIENT_TYPE_PF = 'PF';
export const CLIENT_TYPE_PJ = 'PJ';

export const clientTypes = [
    { type: CLIENT_TYPE_PF },
    { type: CLIENT_TYPE_PJ }
];

export const STATUS_FAIL = 0;
export const STATUS_SUCCESS = 1;
export const STATUS_PENDING = 2;

export const statuses = [
    { value: STATUS_FAIL, text: 'label.fail' },
    { value: STATUS_SUCCESS, text: 'label.success' },
    { value: STATUS_PENDING, text: 'label.pending' }
];

export const orderConstants = {
    ORDER_EDIT_REQUEST: 'ORDER_EDIT_REQUEST',
    ORDER_EDIT_SUCCESS: 'ORDER_EDIT_SUCCESS',
    ORDER_EDIT_FAILURE: 'ORDER_EDIT_FAILURE',

    ORDER_FIND_REQUEST: 'ORDER_FIND_REQUEST',
    ORDER_FIND_SUCCESS: 'ORDER_FIND_SUCCESS',
    ORDER_FIND_FAILURE: 'ORDER_FIND_FAILURE',

    ORDER_ISSUE_PRODUCT_REQUEST: 'ORDER_ISSUE_PRODUCT_REQUEST',
    ORDER_ISSUE_PRODUCT_SUCCESS: 'ORDER_ISSUE_PRODUCT_SUCCESS',
    ORDER_ISSUE_PRODUCT_FAILURE: 'ORDER_ISSUE_PRODUCT_FAILURE',

    ORDER_ISSUE_INVOICE_REQUEST: 'ORDER_ISSUE_INVOICE_REQUEST',
    ORDER_ISSUE_INVOICE_SUCCESS: 'ORDER_ISSUE_INVOICE_SUCCESS',
    ORDER_ISSUE_INVOICE_FAILURE: 'ORDER_ISSUE_INVOICE_FAILURE'
};
