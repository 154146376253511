import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Form, InputNumber, DatePicker, Select, Button } from 'antd';

import { formHelper, styles } from 'dood-react-components';

import { constants } from '../../constants/common.constants';

const { Item: FormItem, create } = Form;

const ExchangeRateForm = create({
    name: 'exchange-rate',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return formHelper.mapPropsToFields(props.exchangeRate, props.fields);
    }
})(({ form, translate, currencies, providers, onSubmit, error }) => {
    const { getFieldDecorator } = form;
    const { formItemLayout, buttonsLayout } = styles;

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <FormItem label={translate('label.value')}>
                {getFieldDecorator('rate', {
                    rules: [{
                        required: true,
                        message: translate('validation.format.rate')
                    }]
                })(
                    <InputNumber placeholder={translate('label.value')} />
                )}
            </FormItem>

            <FormItem label={translate('label.available_from')}>
                {getFieldDecorator('date', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.date')
                    }]
                })(
                    <DatePicker format={constants.DATE_FORMAT} placeholder={translate('label.available_from')} />
                )}
            </FormItem>

            <FormItem label={translate('label.currency')}>
                {getFieldDecorator('currencyId', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.currency')
                    }]
                })(
                    <Select placeholder={translate('label.currency')}>
                        {formHelper.renderOptions(currencies)}
                    </Select>
                )}
            </FormItem>

            <FormItem label={translate('label.provider')}>
                {getFieldDecorator('providerId', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.provider')
                    }]
                })(
                    <Select placeholder={translate('label.provider')}>
                        {formHelper.renderOptions(providers)}
                    </Select>
                )}
            </FormItem>

            <FormItem {...buttonsLayout}>
                <Button type='primary' htmlType='submit'>
                    {translate('button.save')}
                </Button>
            </FormItem>
        </Form>
    );
});

ExchangeRateForm.propTypes = {
    ...formHelper.propTypes,
    exchangeRate: PropTypes.shape({
        rate: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ])
        }),
        date: PropTypes.shape({
            value: PropTypes.object
        }),
        currencyId: PropTypes.shape({
            value: PropTypes.number
        }),
        providerId: PropTypes.shape({
            value: PropTypes.number
        })
    }),
    translate: PropTypes.func.isRequired,
    currencies: PropTypes.array,
    providers: PropTypes.array,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

ExchangeRateForm.defaultProps = {
    ...formHelper.defaultProps,
    currencies: [],
    providers: []
};

export default ExchangeRateForm;
