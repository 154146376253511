import { clientServices } from '../services';

const table = body => {
    return async dispatch => {
        return clientServices.table(body).then(
            table => {
                return table;
            },
            error => {
                return error;
            }
        );
    };
};

export const clientActions = {
    table
};
