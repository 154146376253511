import { providerConstants } from '../constants/provider.constants';
import { providerService } from '../services';

const get = () => {
    const request = () => ({
        type: providerConstants.GET_PROVIDERS_REQUEST
    });

    const success = () => ({
        type: providerConstants.GET_PROVIDERS_SUCCESS
    });

    const failure = () => ({
        type: providerConstants.GET_PROVIDERS_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return providerService.get().then(
            providers => {
                dispatch(success());

                return providers;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

export const providerActions = {
    get
};
