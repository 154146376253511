export const LANGUAGE_EN = 'en';
export const LANGUAGE_RO = 'ro';
export const LANGUAGE_HU = 'hu';

export const LANGUAGES = [
    {
        id: LANGUAGE_EN,
        name: LANGUAGE_EN
    },
    {
        id: LANGUAGE_RO,
        name: LANGUAGE_RO
    },
    {
        id: LANGUAGE_HU,
        name: LANGUAGE_HU
    }
];
