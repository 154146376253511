import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Button, Icon, Alert } from 'antd';

import { formHelper } from 'dood-react-components';

const { Item: FormItem, create } = Form;

const LoginForm = create({
    name: 'login',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return formHelper.mapPropsToFields(props, props.fields);
    }
})(({ form, translate, error, onSubmit, loading }) => {
    const { getFieldDecorator } = form;

    const formItemStyle = {
        marginBottom: 0
    };

    const inputStyle = {
        color: 'rgba(0,0,0,.25)'
    };

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <FormItem style={formItemStyle}>
                {getFieldDecorator('username', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.username')
                    }]
                })(
                    <Input
                        prefix={<Icon type='user' style={inputStyle} />}
                        placeholder={translate('label.username')}
                    />
                )}
            </FormItem>

            <FormItem style={formItemStyle}>
                {getFieldDecorator('password', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.password')
                    }]
                })(
                    <Input
                        prefix={<Icon type='lock' style={inputStyle} />}
                        type='password'
                        placeholder={translate('label.password')}
                    />
                )}
            </FormItem>

            <Button type='primary' htmlType='submit' style={{ width: '100%' }} loading={loading}>
                {translate('label.button.login')}
            </Button>
        </Form>
    );
});

LoginForm.propTypes = {
    ...formHelper.propTypes,
    username: PropTypes.shape({
        value: PropTypes.string
    }),
    password: PropTypes.shape({
        value: PropTypes.string
    }),
    loading: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
    ...formHelper.defaultProps,
    loading: false
};

export default LoginForm;
