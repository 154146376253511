import React from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    InputNumber,
    Alert,
    Row,
    Col,
    Button
} from 'antd';

import { styles } from 'dood-react-components';

import { configConstants } from '../../constants/config.constants';

const { Item: FormItem, create, createFormField } = Form;

const DiscountsForm = create({
    name: 'discounts',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        const { config } = props;

        return {
            [configConstants.KEY_DISCOUNT_INCARCA_RO]: createFormField(config[configConstants.KEY_DISCOUNT_INCARCA_RO]),
            [configConstants.KEY_DISCOUNT_REINCARCARE_RO]: createFormField(config[configConstants.KEY_DISCOUNT_REINCARCARE_RO])
        };
    }
})(({ form, translate, providers, onConfigSave, onCancel, error }) => {
    const { getFieldDecorator } = form;
    const { formItemLayout } = styles;

    const handleConfigSave = key => {
        const { validateFields } = form;

        validateFields([key], errors => {
            if (!errors) {
                onConfigSave(key);
            }
        });
    };

    return (
        <Form {...formItemLayout}>
            {error && <Alert message={error} type='error' />}

            <FormItem label={translate('label.discount')} extra='incarca.ro'>
                <Row gutter={8}>
                    <Col span={6}>
                        {getFieldDecorator([configConstants.KEY_DISCOUNT_INCARCA_RO], {
                            rules: [{
                                required: true,
                                message: translate('validation.required.discount')
                            }]
                        })(
                            <InputNumber
                                min={0}
                                max={100}
                                placeholder={translate('label.discount')}
                                formatter={value => `${value}%`}
                            />
                        )}
                    </Col>
                    <Col span={18}>
                        <Button
                            type='primary'
                            onClick={() => handleConfigSave(configConstants.KEY_DISCOUNT_INCARCA_RO)}
                        >
                            {translate('button.save')}
                        </Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label={translate('label.discount')} extra='re-incarcare.ro'>
                <Row gutter={8}>
                    <Col span={6}>
                        {getFieldDecorator([configConstants.KEY_DISCOUNT_REINCARCARE_RO], {
                            rules: [{
                                required: true,
                                message: translate('validation.required.discount')
                            }]
                        })(
                            <InputNumber
                                min={0}
                                max={100}
                                placeholder={translate('label.discount')}
                                formatter={value => `${value}%`}
                            />
                        )}
                    </Col>
                    <Col span={18}>
                        <Button
                            type='primary'
                            onClick={() => handleConfigSave(configConstants.KEY_DISCOUNT_REINCARCARE_RO)}
                        >
                            {translate('button.save')}
                        </Button>
                    </Col>
                </Row>
            </FormItem>
        </Form>
    );
});

DiscountsForm.propTypes = {
    translate: PropTypes.func.isRequired,
    config: PropTypes.shape({
        [configConstants.KEY_DISCOUNT_INCARCA_RO]: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ])
        }),
        [configConstants.KEY_DISCOUNT_REINCARCARE_RO]: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ])
        })
    }),
    onConfigSave: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    error: PropTypes.string
};

DiscountsForm.defaultProps = {
    config: {},
    error: ''
};

export default DiscountsForm;
