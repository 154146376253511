import axios from 'axios';

const get = () => {
    return axios.get('/currencies').then(
        response => {
            const { currencies } = response.data;

            return currencies;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

export const currencyService = {
    get
};
