import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Button, Icon, Col, Row, Alert } from 'antd';

import { formHelper } from 'dood-react-components';

const { Item: FormItem, create, createFormField } = Form;

const ChangePasswordForm = create({
    name: 'change-password',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return {
            password: createFormField(props.password.password)
        };
    }
})(({ form, translate, error, onSubmit, loading }) => {
    const { getFieldDecorator } = form;

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <FormItem>
                <Row gutter={8}>
                    <Col span={8}>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: translate('validation.required.password')
                            }]
                        })(
                            <Input
                                prefix={<Icon type='lock' />}
                                type='password'
                                placeholder={translate('label.password')}
                            />
                        )}
                    </Col>
                    <Col span={16}>
                        <Button type='primary' htmlType='submit' loading={loading}>
                            {translate('button.save')}
                        </Button>
                    </Col>
                </Row>
            </FormItem>
        </Form>
    );
});

ChangePasswordForm.propTypes = {
    ...formHelper.propTypes,
    password: PropTypes.shape({
        value: PropTypes.string
    }),
    loading: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

ChangePasswordForm.defaultProps = {
    ...formHelper.defaultProps,
    loading: false
};

export default ChangePasswordForm;
