import { fileService } from '../services';

const upload = (file, path) => {
    return async dispatch => {
        return fileService.upload(file, path)
            .then(
                files => files,
                error => error
            );
    };
};

export const fileActions = {
    upload
};
