import axios from 'axios';

const upload = (file, path) => {
    const formData = new FormData();
    formData.set('file', file);

    return axios
        .post(path, formData)
        .then(
            response => {
                const { files } = response.data;

                return files;
            },
            error => {
                const { response } = error;
                const { data } = response;
                const { errors } = data;

                return Promise.reject(errors);
            }
        );
};

export const fileService = {
    upload
};
