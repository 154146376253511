import { authenticationConstants } from '../constants/authentication.constants';

export const authentication = (state = {}, action) => {
    switch (action.type) {
        case authenticationConstants.AUTHENTICATION_LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };

        case authenticationConstants.AUTHENTICATION_LOGIN_FAILURE:
            return {
                loggedIn: false,
                error: action.error
            };

        case authenticationConstants.AUTHENTICATION_LOGOUT_REQUEST:
        case authenticationConstants.AUTHENTICATION_LOGOUT_SUCCESS:
            return {
                loggedIn: false
            };

        case authenticationConstants.AUTHENTICATION_LOGOUT_FAILURE:
            return {
                loggedIn: false,
                error: action.error
            };

        case authenticationConstants.AUTHENTICATION_STATUS_SUCCESS:
            return {
                loggedIn: true
            };

        case authenticationConstants.AUTHENTICATION_STATUS_FAILURE:
            return {
                loggedIn: false,
                error: action.error
            };

        default:
            return state;
    }
};
