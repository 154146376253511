import axios from 'axios';

const table = body => {
    return axios.post('/order-tables', body).then(
        response => {
            const { table } = response.data;

            return table;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

const edit = (id, body) => {
    return axios
        .put(`/orders/${id}`, body)
        .then(
            response => {
                const { order } = response.data;

                return order;
            },
            error => {
                return Promise.reject(error.response.data.errors);
            }
        );
};

const find = id => {
    return axios
        .get(`/orders/${id}`)
        .then(
            response => {
                const { order } = response.data;

                return order;
            },
            error => {
                const { response } = error;
                const { data } = response;
                const { errors } = data;

                return Promise.reject(errors);
            }
        );
};

const issueProduct = id => {
    return axios
        .put(`/orders/${id}/issue-product`)
        .then(
            response => {
                const { order } = response.data;

                return order;
            },
            error => {
                return Promise.reject(error.response.data.errors);
            }
        );
};

const issueInvoice = id => {
    return axios
        .put(`/orders/${id}/issue-invoice`)
        .then(
            response => {
                const { order } = response.data;

                return order;
            },
            error => {
                return Promise.reject(error.response.data.errors);
            }
        );
};

export const orderServices = {
    table,
    edit,
    find,
    issueProduct,
    issueInvoice
};
