import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    Card,
    Col,
    DatePicker,
    Divider,
    Row,
    Skeleton,
    Statistic,
    Alert
} from 'antd';
import Chart from 'react-apexcharts';

import { styles } from 'dood-react-components';

import { translator, createLoadingSelector } from '../../helpers';
import { loadingConstants } from '../../constants/loading.constants';
import { statsActions, configActions } from '../../actions';
import { constants } from '../../constants/common.constants';
import { configConstants } from '../../constants/config.constants';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            start: moment().startOf('month'),
            end: moment(),
            count: 0,
            income: 0,
            chart: {
                dates: [],
                counts: []
            },
            maintenance: []
        };
    }

    componentDidMount() {
        this.loadStats();
    }

    handleDateRangeChange = dates => {
        this.setState({
            start: dates[0],
            end: dates[1]
        }, () => {
            this.loadStats();
        });
    };

    loadStats = () => {
        const { start, end } = this.state;

        this.props.getStats(start.format(constants.DATE_FORMAT), end.format(constants.DATE_FORMAT)).then(
            stats => {
                const { countTotal, incomeTotal, countIncarca, incomeIncarca, countReincarcare, incomeReincarcare, groups } = stats;

                let dates = [];
                let counts = [];

                groups.forEach(group => {
                    dates.push(group.date);
                    counts.push(group.count);
                });

                this.setState({
                    countTotal,
                    incomeTotal,
                    countIncarca,
                    incomeIncarca,
                    countReincarcare,
                    incomeReincarcare,
                    chart: {
                        dates,
                        counts
                    },
                    loading: false
                });
            }
        );

        this.props.getMaintenance(configConstants.KEY_MAINTENANCE_PERIOD).then(
            result => {
                result = JSON.parse(result.value);
                const maintenance = !Array.isArray(result) ? [result.startDate, result.endDate] : [];

                this.setState({ maintenance });
            }
        );
    };

    renderMaintenanceInfo = () => {
        const { maintenance } = this.state;

        if (maintenance.length === 0) {
            return this.props.translate('message.no_maintenance_period');
        }

        return `${this.props.translate('message.maintenance_period')} ${maintenance[0]} - ${maintenance[1]}`;
    };

    render() {
        const { loading } = this.state;

        if (loading) {
            return (<Skeleton />);
        }

        const { columnLayout } = styles;

        const { countTotal, incomeTotal, countIncarca, incomeIncarca, countReincarcare, incomeReincarcare, chart } = this.state;
        const { translate } = this.props;

        const { RangePicker } = DatePicker;

        return (
            <div>
                <Row gutter={16} type='flex' justify='start'>
                    <Col {...columnLayout}>
                        <RangePicker
                            defaultValue={[this.state.start, this.state.end]}
                            format={constants.DATE_FORMAT}
                            onChange={this.handleDateRangeChange}
                            placeholder={[translate('label.start_date'), translate('label.end_date')]}
                            allowClear={false}
                        />
                    </Col>
                    <Col {...columnLayout}>
                        <Alert
                            message={translate('title.maintenance_period')}
                            description={this.renderMaintenanceInfo()}
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>

                <Divider />

                <Row type='flex' justify='start'>
                    <Col {...columnLayout} style={{ paddingRight: 20 }}>
                        <Row type='flex' justify='start' style={{ marginBottom: 80 }}>
                            <Col span={24} style={{ marginRight: 200 }}>
                                <Card
                                    title={translate('label.orders_incarca')}
                                    style={{ width: '100%', height: 150, marginTop: 16 }}
                                    bordered={false}
                                    loading={false}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Statistic
                                                title={translate('label.total')}
                                                value={countIncarca}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title={translate('label.income_ron')}
                                                value={incomeIncarca}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24} style={{ marginRight: 200 }}>
                                <Card
                                    title={translate('label.orders_reincarcare')}
                                    style={{ width: '100%', height: 150, marginTop: 16 }}
                                    bordered={false}
                                    loading={false}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Statistic
                                                title={translate('label.total')}
                                                value={countReincarcare}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title={translate('label.income_ron')}
                                                value={incomeReincarcare}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col span={24} style={{ marginRight: 200 }}>
                                <Card
                                    title={translate('label.orders_total')}
                                    style={{ width: '100%', height: 150, marginTop: 16 }}
                                    bordered={false}
                                    loading={false}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Statistic
                                                title={translate('label.total')}
                                                value={countTotal}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title={translate('label.income_ron')}
                                                value={incomeTotal}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col {...columnLayout}>
                        <Chart
                            options={{
                                xaxis: {
                                    categories: chart.dates
                                }
                            }}
                            series={[{
                                name: translate('label.orders_count'),
                                data: chart.counts
                            }]}
                            type='line'
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state),
        loading: createLoadingSelector([
            loadingConstants.DASHBOARD_STATS,
            loadingConstants.CONFIG_FIND
        ])(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getStats: (start, end) => dispatch(statsActions.dashboard(start, end)),
        getMaintenance: key => dispatch(configActions.find(key))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
