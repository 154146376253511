import axios from 'axios';

import { httpConstants } from '../constants/http.constants';
import { authenticationServices } from '../services';

const setup = () => {
    axios.interceptors.request.use(config => {
        config.url = process.env.REACT_APP_API_PREFIX + config.url;

        return config;
    });

    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const { config, status } = error.response;
            const { requiresAuth = true } = config;

            const { HttpResponseCodes } = httpConstants;

            if (requiresAuth && [HttpResponseCodes.UNAUTHORIZED, HttpResponseCodes.FORBIDDEN].indexOf(status) !== -1) {
                authenticationServices.logout().then(() => {
                    window.location.reload(true);
                });
            }

            return Promise.reject(error);
        }
    );
};

export const interceptors = {
    setup
};
