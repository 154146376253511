export const authenticationConstants = {
    // Login
    AUTHENTICATION_LOGIN_REQUEST: 'AUTHENTICATION_LOGIN_REQUEST',
    AUTHENTICATION_LOGIN_SUCCESS: 'AUTHENTICATION_LOGIN_SUCCESS',
    AUTHENTICATION_LOGIN_FAILURE: 'AUTHENTICATION_LOGIN_FAILURE',

    // Logout
    AUTHENTICATION_LOGOUT_REQUEST: 'AUTHENTICATION_LOGOUT_REQUEST',
    AUTHENTICATION_LOGOUT_SUCCESS: 'AUTHENTICATION_LOGOUT_SUCCESS',
    AUTHENTICATION_LOGOUT_FAILURE: 'AUTHENTICATION_LOGOUT_FAILURE',

    // Status
    AUTHENTICATION_STATUS_REQUEST: 'AUTHENTICATION_STATUS_REQUEST',
    AUTHENTICATION_STATUS_SUCCESS: 'AUTHENTICATION_STATUS_SUCCESS',
    AUTHENTICATION_STATUS_FAILURE: 'AUTHENTICATION_STATUS_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE'
};
