import React from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    Divider,
    Alert,
    Input,
    Checkbox,
    Select,
    Slider,
    Button
} from 'antd';

import { formHelper, styles } from 'dood-react-components';

import { categoryConstants } from '../../constants/category.constants';

const { Item: FormItem, create } = Form;
const { TextArea } = Input;

const CategoryForm = create({
    name: 'category',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return formHelper.mapPropsToFields(props.category, props.fields);
    }
})(({ form, translate, providers, onSubmit, onCancel, error }) => {
    const { getFieldDecorator, getFieldValue } = form;
    const { formItemLayout, buttonsLayout } = styles;

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <FormItem label={translate('label.provider')}>
                {getFieldDecorator('providerId', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.provider')
                    }]
                })(
                    <Select placeholder={translate('label.provider')}>
                        {formHelper.renderOptions(providers)}
                    </Select>
                )}
            </FormItem>

            <FormItem label={translate('label.display_order')}>
                {getFieldDecorator('displayOrder', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.display_order')
                    }]
                })(
                    <Slider min={categoryConstants.MIN_DISPLAY_ORDER} max={categoryConstants.MAX_DISPLAY_ORDER} />
                )}
            </FormItem>

            <FormItem label={translate('label.active')}>
                {getFieldDecorator('isActive')(
                    <Checkbox defaultChecked={getFieldValue('isActive')} />
                )}
            </FormItem>

            <Divider>{translate('label.romanian')}</Divider>

            <FormItem label={translate('label.title')}>
                {getFieldDecorator('titleRo', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.title')
                    }]
                })(
                    <Input placeholder={translate('label.title')} />
                )}
            </FormItem>

            <FormItem label={translate('label.description')}>
                {getFieldDecorator('descriptionRo', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.description')
                    }]
                })(
                    <TextArea placeholder={translate('label.description')} />
                )}
            </FormItem>

            <Divider>{translate('label.english')}</Divider>

            <FormItem label={translate('label.title')}>
                {getFieldDecorator('titleEn', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.title')
                    }]
                })(
                    <Input placeholder={translate('label.title')} />
                )}
            </FormItem>

            <FormItem label={translate('label.description')}>
                {getFieldDecorator('descriptionEn', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.description')
                    }]
                })(
                    <TextArea placeholder={translate('label.description')} />
                )}
            </FormItem>

            <Divider>{translate('label.hungarian')}</Divider>

            <FormItem label={translate('label.title')}>
                {getFieldDecorator('titleHu', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.title')
                    }]
                })(
                    <Input placeholder={translate('label.title')} />
                )}
            </FormItem>

            <FormItem label={translate('label.description')}>
                {getFieldDecorator('descriptionHu', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.description')
                    }]
                })(
                    <TextArea placeholder={translate('label.description')} />
                )}
            </FormItem>

            <FormItem {...buttonsLayout}>
                <Button type='primary' htmlType='submit'>
                    {translate('button.save')}
                </Button>

                <Button htmlType='button' style={{ marginLeft: 15 }} onClick={onCancel}>
                    {translate('button.cancel')}
                </Button>
            </FormItem>
        </Form>
    );
});

CategoryForm.propTypes = {
    ...formHelper.propTypes,
    translate: PropTypes.func.isRequired,
    category: PropTypes.shape({
        providerId: PropTypes.shape({
            value: PropTypes.number
        }),
        displayOrder: PropTypes.shape({
            value: PropTypes.number
        }),
        isActive: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.number
            ])
        }),
        titleRo: PropTypes.shape({
            value: PropTypes.string
        }),
        descriptionRo: PropTypes.shape({
            value: PropTypes.string
        }),
        titleEn: PropTypes.shape({
            value: PropTypes.string
        }),
        descriptionEn: PropTypes.shape({
            value: PropTypes.string
        }),
        titleHu: PropTypes.shape({
            value: PropTypes.string
        }),
        descriptionHu: PropTypes.shape({
            value: PropTypes.string
        })
    }),
    providers: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired
};

CategoryForm.defaultProps = {
    ...formHelper.defaultProps,
    providers: []
};

export default CategoryForm;
