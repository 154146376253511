import axios from 'axios';

const login = (username, password) => {
    return axios
        .post('/login', { username, password }, {
            requiresAuth: false
        })
        .then(
            response => {
                const { user } = response.data;

                return user;
            },
            error => {
                const { response } = error;
                const { data } = response;
                const { errors } = data;

                return Promise.reject(errors);
            }
        );
};

const logout = () => {
    return axios
        .post('/logout')
        .then(
            () => {
                return {};
            },
            () => {
                return Promise.reject();
            }
        );
};

const me = () => {
    return axios
        .get('/me', {
            requiresAuth: false
        })
        .then(
            () => {
                return {};
            },
            () => {
                return Promise.reject();
            }
        );
};

const changePassword = password => {
    return axios.put('/change-password', password).then(
        response => {
            return response.data;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

export const authenticationServices = {
    login,
    logout,
    me,
    changePassword
};
