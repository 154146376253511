import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from 'antd';
import { Table as DoodTable, TableContainer } from 'dood-react-components';

import { exchangeRateActions } from '../../actions';
import { translator } from '../../helpers';
import { tableConstants } from '../../constants/table.constants';

import './Table.scss';

const { confirm } = Modal;

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exchangeRates: null,
            loading: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = options => {
        this.props.dispatch(exchangeRateActions.table(options)).then(
            result => {
                this.setState({
                    exchangeRates: result,
                    pagination: result.pagination,
                    filters: result.filters,
                    loading: false
                });
            },
            error => {
                this.setState({ error });
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ loading: true });

        this.loadData({
            ...this.state.exchangeRates.options,
            pagination: { ...pagination, current: pagination.current },
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
            filters: filters
        });
    };

    handleActionClick = (action, rowData) => {
        if (action !== tableConstants.ACTION_REMOVE) {
            return;
        }

        confirm({
            title: this.props.translate('modal.title.are_you_sure'),
            okText: this.props.translate('label.yes'),
            okType: 'danger',
            cancelText: this.props.translate('label.no'),
            onOk: () => {
                return this.handleDelete(rowData.id);
            }
        });
    };

    handleDelete = id => {
        return new Promise((resolve, reject) => {
            this.props.dispatch(exchangeRateActions.remove(id)).then(
                () => {
                    const data = this.state.exchangeRates.data.filter(exchangeRate => exchangeRate.id !== id);

                    this.setState(previousState => ({
                        exchangeRates: {
                            ...previousState.exchangeRates,
                            data
                        }
                    }));

                    resolve();
                },
                error => {
                    this.setState({ error });

                    reject();
                }
            );
        });
    };

    render() {
        const { exchangeRates, loading } = this.state;

        let tableComponent = null;

        if (exchangeRates) {
            const { data, columns, filters, pagination } = exchangeRates;

            tableComponent = (
                <DoodTable
                    translate={this.props.translate}
                    loading={loading}
                    data={data}
                    columns={columns}
                    filters={filters}
                    pagination={pagination}
                    onActionClick={this.handleActionClick}
                    onTableChange={this.handleTableChange}
                />
            );
        }

        return (
            <TableContainer
                translate={this.props.translate}
                title={this.props.translate('title.exchange_rates')}
                tableComponent={tableComponent}
                error={this.state.error}
                createRoute='/exchange-rates/create'
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state)
    };
};

export default connect(mapStateToProps)(Table);
