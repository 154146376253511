import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { FormContainer, formHelper } from 'dood-react-components';

import ExchangeRateForm from '../../components/Form/ExchangeRateForm';

import { createLoadingSelector, translator } from '../../helpers';
import { exchangeRateActions, currencyActions, providerActions } from '../../actions';
import { constants } from '../../constants/common.constants';
import { loadingConstants } from '../../constants/loading.constants';
import { currencyConstants } from '../../constants/currency.constants';

class Form extends Component {
    static fields = [
        'rate',
        'date',
        'currencyId',
        'providerId'
    ];

    constructor(props) {
        super(props);

        this.state = {
            exchangeRate: formHelper.createStateFromFields(Form.fields, {
                currencyId: currencyConstants.ID_EUR
            }),
            currencies: [],
            providers: []
        };
    }

    componentDidMount() {
        this.props.getCurrencies().then(
            currencies => {
                this.setState({ currencies });
            }
        );

        this.props.getProviders().then(
            providers => {
                this.setState({ providers });
            }
        );
    }

    handleInputChange = changedFields => {
        this.setState(prevState => ({
            ...prevState,
            exchangeRate: {
                ...prevState.exchangeRate,
                ...changedFields
            }
        }));
    };

    handleSubmit = () => {
        const { exchangeRate } = this.state;

        let data = {};

        Form.fields.forEach(field => {
            data[field] = exchangeRate[field].value;

            // clear form errors
            delete exchangeRate[field].errors;
        });

        data = {
            ...data,
            date: moment(data.date).format(constants.DATE_FORMAT)
        };

        this.props.createExchangeRate(data).then(
            () => {
                this.props.history.push('/exchange-rates');
            },
            errors => {
                const { exchangeRate } = this.state;

                const { error, object } = formHelper.mapErrorsToFields(exchangeRate, errors);

                this.setState({ exchangeRate: object, error });
            }
        );
    };

    render() {
        const formComponent = (
            <ExchangeRateForm
                exchangeRate={this.state.exchangeRate}
                translate={this.props.translate}
                currencies={this.state.currencies}
                providers={this.state.providers}
                onInputChange={this.handleInputChange}
                onSubmit={this.handleSubmit}
                error={this.state.error}
                fields={Form.fields}
            />
        );

        return (
            <FormContainer
                title={this.props.translate('title.new_exchange_rate')}
                loading={this.props.loading}
                formComponent={formComponent}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state),
        loading: createLoadingSelector([
            loadingConstants.GET_CURRENCIES,
            loadingConstants.GET_PROVIDERS,
            loadingConstants.EXCHANGE_RATE_CREATE
        ])(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrencies: () => dispatch(currencyActions.get()),
        getProviders: () => dispatch(providerActions.get()),
        createExchangeRate: exchangeRate => dispatch(exchangeRateActions.create(exchangeRate))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
