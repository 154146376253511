import React from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    Alert,
    Input,
    Select,
    Button,
    Icon,
    Row,
    Col
} from 'antd';
import ReactQuill from 'react-quill';

import { formHelper, styles } from 'dood-react-components';

import './ArticleForm.scss';

import Upload from './Upload';

import { LANGUAGES } from '../../constants/languages.constants';
import { uploadConstants } from '../../constants/upload.constants';
import { generator } from '../../helpers';

const { Item: FormItem, create } = Form;

const PostForm = create({
    name: 'post',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return formHelper.mapPropsToFields(props.post, props.fields);
    }
})(({ form, translate, onSubmit, isEdit, upload, onCancel, error }) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const { formItemLayout, columnLayout, buttonsLayout } = styles;

    const imageLayout = { ...columnLayout, md: { span: 8 } };
    const fieldsLayout = { ...columnLayout, md: { span: 14 } };
    const formLayout = { ...formItemLayout, labelCol: { md: { span: 4 } }, wrapperCol: { md: { span: 20 } } };
    const actionsLayout = { ...buttonsLayout, wrapperCol: { md: { offset: 4 } } };

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    const displayUploadContent = (url, loading) => {
        if (url) {
            return (<img src={url} alt="file" style={{ width: '100%' }} />);
        }

        return (
            <div>
                <Icon type={loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{translate('button.upload')}</div>
            </div>
        );
    };

    return (
        <Form {...formLayout} onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <Row gutter={8}>
                <Col className="upload-image" {...imageLayout}>
                    <FormItem>
                        {getFieldDecorator('fileId', {
                            rules: [{
                                required: true,
                                message: translate('validation.required.image')
                            }]
                        })(
                            <Upload
                                name='fileId'
                                initialValue={isEdit ? generator.generateFileUrl(getFieldValue('fileId')) : ''}
                                type="image"
                                listType="picture-card"
                                upload={upload}
                                setFormField={files => setFieldsValue({ 'fileId': files.length > 0 ? files[0] : null })}
                                allowedTypes={uploadConstants.ALLOWED_IMAGE_TYPES}
                                allowedSize={uploadConstants.ALLOWED_IMAGE_SIZE}
                                translate={translate}
                                displayContent={displayUploadContent}
                            />
                        )}

                    </FormItem>
                </Col>
                <Col {...fieldsLayout}>
                    <FormItem label={translate('label.language')}>
                        {getFieldDecorator('language', {
                            rules: [{
                                required: true,
                                message: translate('validation.required.language')
                            }]
                        })(
                            <Select placeholder={translate('label.category')}>
                                {formHelper.renderOptions(LANGUAGES)}
                            </Select>
                        )}
                    </FormItem>

                    <FormItem label={translate('label.title')}>
                        {getFieldDecorator('title', {
                            rules: [{
                                required: true,
                                message: translate('validation.required.title')
                            }]
                        })(
                            <Input placeholder={translate('label.title')} />
                        )}
                    </FormItem>

                    <FormItem label={translate('label.description')}>
                        {getFieldDecorator('description', {
                            initialValue: '',
                            rules: [{
                                required: true,
                                message: translate('validation.required.description')
                            }]
                        })(
                            <ReactQuill placeholder={translate('label.description')} />
                        )}
                    </FormItem>

                    <FormItem label={translate('label.meta_title')}>
                        {getFieldDecorator('metaTitle', {
                            rules: [{
                                required: true,
                                message: translate('validation.required.meta_title')
                            }]
                        })(
                            <Input placeholder={translate('label.meta_title')} />
                        )}
                    </FormItem>

                    <FormItem label={translate('label.meta_description')}>
                        {getFieldDecorator('metaDescription', {
                            initialValue: '',
                            rules: [{
                                required: true,
                                message: translate('validation.required.meta_description')
                            }]
                        })(
                            <Input placeholder={translate('label.meta_description')} />
                        )}
                    </FormItem>

                    <FormItem label={translate('label.link')}>
                        {getFieldDecorator('slug', {
                            initialValue: '',
                            rules: [{
                                required: true,
                                message: translate('validation.required.link')
                            }]
                        })(
                            <Input placeholder={translate('label.link')} />
                        )}
                    </FormItem>

                    <FormItem label={translate('label.origin')}>
                        {getFieldDecorator('origin', {
                            rules: [{
                                required: true,
                                message: 'Originea postului este obligatorie'
                            }]
                        })(
                            <Select placeholder={translate('label.origin')}>
                                {formHelper.renderOptions([
                                    {
                                        id: 'incarca.ro',
                                        name: 'incarca.ro'
                                    },
                                    {
                                        id: 're-incarcare.ro',
                                        name: 're-incarcare.ro'
                                    }
                                ])}
                            </Select>)}
                    </FormItem>

                    <FormItem {...actionsLayout}>
                        <Button type='primary' htmlType='submit'>
                            {translate('button.save')}
                        </Button>

                        <Button htmlType='button' style={{ marginLeft: 15 }} onClick={onCancel}>
                            {translate('button.cancel')}
                        </Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    );
});

PostForm.propTypes = {
    ...formHelper.propTypes,
    translate: PropTypes.func.isRequired,
    post: PropTypes.shape({
        title: PropTypes.shape({
            value: PropTypes.string
        }),
        description: PropTypes.shape({
            value: PropTypes.string
        }),
        metaTitle: PropTypes.shape({
            value: PropTypes.string
        }),
        metaDescription: PropTypes.shape({
            value: PropTypes.string
        }),
        language: PropTypes.shape({
            value: PropTypes.string
        }),
        fileId: PropTypes.shape({
            value: PropTypes.string
        })
    }),
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired
};

PostForm.defaultProps = {
    ...formHelper.defaultProps
};

export default PostForm;
