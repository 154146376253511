import { menuServices } from '../services';
import { menuConstants } from '../constants/menu.constants';

const getMenu = (type, name, isLoggedIn) => {
    const request = () => ({
        type: menuConstants.FETCH_MENU_REQUEST
    });

    const success = menu => ({
        type: menuConstants.FETCH_MENU_SUCCESS,
        menu
    });

    const failure = error => ({
        type: menuConstants.FETCH_MENU_FAILURE,
        error
    });

    return dispatch => {
        dispatch(request());

        menuServices.getMenu(type, name, isLoggedIn)
            .then(
                menu => {
                    dispatch(success(menu));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
};

export const menuActions = {
    getMenu
};
