import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Layout } from 'antd';

import { Header, Main } from 'dood-react-components';

import { default as Categories } from '../containers/Categories/Table';
import { default as CategoryFormContainer } from '../containers/Categories/Form';
import { default as ExchangeRates } from '../containers/ExchangeRates/Table';
import { default as ExchangeRateFormContainer } from '../containers/ExchangeRates/Form';
import { default as Orders } from '../containers/Orders/Table';
import { default as OrderFormContainer } from '../containers/Orders/Form';
import { default as Products } from '../containers/Products/Table';
import { default as ProductFormContainer } from '../containers/Products/Form';
import { default as Posts } from '../containers/Posts/Table';
import { default as PostFormContainer } from '../containers/Posts/Form';
import { default as News } from '../containers/News/Table';
import { default as NewsFormContainer } from '../containers/News/Form';
import { default as Clients } from '../containers/Clients/Table';
import Dashboard from '../containers/Dashboard/Dashboard';
import Settings from '../containers/Settings/Settings';
import Login from '../containers/Login/Login';
import Logout from '../containers/Logout/Logout';
import NotFound from '../containers/404/NotFound';

import { authenticationActions, translationsActions, menuActions } from '../actions';
import { createLoadingSelector, translator } from '../helpers';
import { loadingConstants } from '../constants/loading.constants';
import { menuConstants } from '../constants/menu.constants';

import '../styles/App.scss';

class App extends Component {
    componentDidMount() {
        this.props.getAuthenticationStatus();
        this.props.getTranslations();
        this.props.getMainMenu(this.props.isLoggedIn);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
            this.props.getMainMenu(this.props.isLoggedIn);
        }
    }

    render() {
        const { Content } = Layout;

        const { translate, loading, menu, isLoggedIn } = this.props;

        const routes = [
            // categories
            {
                isPrivate: true,
                exact: true,
                path: '/categories',
                component: Categories,
                isAuthenticated: isLoggedIn
            },
            {
                isPrivate: true,
                path: '/categories/create',
                component: CategoryFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: false
            },
            {
                isPrivate: true,
                path: '/categories/:id/edit',
                component: CategoryFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: true
            },
            // exchange-rates
            {
                isPrivate: true,
                exact: true,
                path: '/exchange-rates',
                component: ExchangeRates,
                isAuthenticated: isLoggedIn
            },
            {
                isPrivate: true,
                exact: true,
                path: '/exchange-rates/create',
                component: ExchangeRateFormContainer,
                isAuthenticated: isLoggedIn
            },
            // orders
            {
                isPrivate: true,
                exact: true,
                path: '/orders',
                component: Orders,
                isAuthenticated: isLoggedIn
            },
            {
                isPrivate: true,
                exact: true,
                path: '/orders/:id/edit',
                component: OrderFormContainer,
                isAuthenticated: isLoggedIn
            },
            // products
            {
                isPrivate: true,
                exact: true,
                path: '/products',
                component: Products,
                isAuthenticated: isLoggedIn
            },
            {
                isPrivate: true,
                exact: true,
                path: '/products/create',
                component: ProductFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: false
            },
            {
                isPrivate: true,
                exact: true,
                path: '/products/:id/edit',
                component: ProductFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: true
            },
            // settings
            {
                isPrivate: true,
                path: '/settings',
                component: Settings,
                isAuthenticated: isLoggedIn
            },
            // logout
            {
                isPrivate: true,
                path: '/logout',
                component: Logout,
                isAuthenticated: isLoggedIn
            },
            // dashboard
            {
                isPrivate: true,
                exact: true,
                path: '/',
                component: Dashboard,
                isAuthenticated: isLoggedIn
            },
            // posts
            {
                isPrivate: true,
                exact: true,
                path: '/posts',
                component: Posts,
                isAuthenticated: isLoggedIn
            },
            {
                isPrivate: true,
                exact: true,
                path: '/posts/create',
                component: PostFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: false
            },
            {
                isPrivate: true,
                exact: true,
                path: '/posts/:id/edit',
                component: PostFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: true
            },
            {
                isPrivate: true,
                exact: true,
                path: '/news',
                component: News,
                isAuthenticated: isLoggedIn
            },
            {
                isPrivate: true,
                exact: true,
                path: '/news/create',
                component: NewsFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: false
            },
            {
                isPrivate: true,
                exact: true,
                path: '/news/:id/edit',
                component: NewsFormContainer,
                isAuthenticated: isLoggedIn,
                isEdit: true
            },
            {
                isPrivate: true,
                exact: true,
                path: '/clients',
                component: Clients,
                isAuthenticated: isLoggedIn
            },
            // login
            {
                isPrivate: false,
                path: '/login',
                component: Login
            },
            // not found
            {
                component: NotFound
            }
        ];

        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Header
                    translate={translate}
                    isLoading={loading}
                    menu={menu}
                />

                <Content style={{ padding: '24px 48px' }}>
                    <Layout style={{ padding: '24px 0', background: '#fff', minHeight: 'calc(100vh - 120px)' }}>
                        <Content style={{ padding: '0 24px' }}>
                            <Main
                                isLoading={loading}
                                routes={routes}
                            />
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    const { authentication, menu } = state;

    return {
        loading: createLoadingSelector([
            loadingConstants.AUTHENTICATION_STATUS,
            loadingConstants.FETCH_TRANSLATIONS,
            loadingConstants.FETCH_MENU
        ])(state),
        translate: translator(state),
        isLoggedIn: authentication.loggedIn,
        menu: menu.menu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAuthenticationStatus: () => dispatch(authenticationActions.me()),
        getTranslations: () => dispatch(translationsActions.getTranslations()),
        getMainMenu: (isLoggedIn) => dispatch(menuActions.getMenu(
            menuConstants.TOPBAR_MENU_TYPE_ID,
            menuConstants.MAIN_MENU,
            isLoggedIn
        ))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
