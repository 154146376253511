import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table as DoodTable, TableContainer } from 'dood-react-components';

import { orderActions } from '../../actions';
import { translator } from '../../helpers';

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = options => {
        this.props.dispatch(orderActions.table(options)).then(
            result => {
                this.setState({
                    orders: result,
                    pagination: result.pagination,
                    filters: result.filters,
                    loading: false
                });
            },
            error => {
                this.setState({ error });
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ loading: true });

        this.loadData({
            ...this.state.orders.options,
            pagination: { ...pagination, current: pagination.current },
            sortField: sorter.columnKey ? sorter.columnKey : 'created_at',
            sortOrder: sorter.order ? sorter.order : 'DESC',
            filters: filters
        });
    };

    render() {
        const { orders, loading } = this.state;

        let tableComponent = null;

        if (orders) {
            const { data, columns, filters, pagination } = orders;

            tableComponent = (
                <DoodTable
                    translate={this.props.translate}
                    loading={loading}
                    data={data}
                    columns={columns}
                    filters={filters}
                    pagination={pagination}
                    onActionClick={this.handleActionClick}
                    onTableChange={this.handleTableChange}
                />
            );
        }

        return (
            <TableContainer
                translate={this.props.translate}
                title={this.props.translate('title.orders')}
                tableComponent={tableComponent}
                error={this.state.error}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state)
    };
};

export default connect(mapStateToProps)(Table);
