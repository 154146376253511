import { orderServices } from '../services';
import { orderConstants } from '../constants/order.constants';

const table = body => {
    return async () => {
        return orderServices.table(body).then(
            table => {
                return table;
            },
            error => {
                return error;
            }
        );
    };
};

const edit = (id, body) => {
    const request = () => ({
        type: orderConstants.ORDER_EDIT_REQUEST
    });

    const success = () => ({
        type: orderConstants.ORDER_EDIT_SUCCESS
    });

    const failure = () => ({
        type: orderConstants.ORDER_EDIT_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        orderServices.edit(id, body).then(
            order => {
                dispatch(success());

                resolve(order);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const find = id => {
    const request = () => ({
        type: orderConstants.ORDER_FIND_REQUEST
    });

    const success = () => ({
        type: orderConstants.ORDER_FIND_SUCCESS
    });

    const failure = () => ({
        type: orderConstants.ORDER_FIND_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return orderServices.find(id)
            .then(
                order => {
                    dispatch(success());

                    return order;
                },
                error => {
                    dispatch(failure());

                    return error;
                }
            );
    };
};

const issueProduct = id => {
    const request = () => ({
        type: orderConstants.ORDER_ISSUE_PRODUCT_REQUEST
    });

    const success = () => ({
        type: orderConstants.ORDER_ISSUE_PRODUCT_SUCCESS
    });

    const failure = () => ({
        type: orderConstants.ORDER_ISSUE_PRODUCT_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        orderServices.issueProduct(id).then(
            order => {
                dispatch(success());

                resolve(order);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const issueInvoice = id => {
    const request = () => ({
        type: orderConstants.ORDER_ISSUE_INVOICE_REQUEST
    });

    const success = () => ({
        type: orderConstants.ORDER_ISSUE_INVOICE_SUCCESS
    });

    const failure = () => ({
        type: orderConstants.ORDER_ISSUE_INVOICE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        orderServices.issueInvoice(id).then(
            order => {
                dispatch(success());

                resolve(order);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

export const orderActions = {
    table,
    find,
    edit,
    issueProduct,
    issueInvoice
};
