import axios from 'axios';

const table = body => {
    return axios.post('/exchange-rate-tables', body).then(
        response => {
            const { table } = response.data;

            return table;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

const create = body => {
    return axios.post('/exchange-rates', body).then(
        response => {
            const { exchangeRate } = response.data;

            return exchangeRate;
        },
        error => {
            return Promise.reject(error.response.data.errors);
        }
    );
};

const remove = id => {
    return axios.delete('/exchange-rates/' + id).then(
        () => {},
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return errors;
        }
    );
};

export const exchangeRateServices = {
    table,
    create,
    remove
};
