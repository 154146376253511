import { currencyConstants } from '../constants/currency.constants';
import { currencyService } from '../services';

const get = () => {
    const request = () => ({
        type: currencyConstants.GET_CURRENCIES_REQUEST
    });

    const success = () => ({
        type: currencyConstants.GET_CURRENCIES_SUCCESS
    });

    const failure = () => ({
        type: currencyConstants.GET_CURRENCIES_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return currencyService.get().then(
            currencies => {
                dispatch(success());

                return currencies;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

export const currencyActions = {
    get
};
