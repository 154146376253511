import { translationsService } from '../services';
import { translationsConstants } from '../constants/translations.constants';

const getTranslations = () => {
    const request = () => ({
        type: translationsConstants.FETCH_TRANSLATIONS_REQUEST
    });

    const success = translations => ({
        type: translationsConstants.FETCH_TRANSLATIONS_SUCCESS,
        translations
    });

    const failure = error => ({
        type: translationsConstants.FETCH_TRANSLATIONS_FAILURE,
        error
    });

    return dispatch => {
        dispatch(request());

        translationsService.getTranslations()
            .then(
                translations => {
                    dispatch(success(translations));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
};

export const translationsActions = {
    getTranslations
};
