import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { translator } from '../../helpers';
import { connect } from 'react-redux';

class NotFound extends Component {
    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2>{this.props.translate('title.not_found')}</h2>

                        <span>{this.props.translate('message.not_found')}</span>

                        <div className='error-actions mt-3'>
                            <Link to='/' className='btn btn-primary'>{this.props.translate('label.menu.dashboard')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state)
    };
};

export default connect(mapStateToProps)(NotFound);
