import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Upload as AntUpload, message } from 'antd';
import { uploadConstants } from '../../constants/upload.constants';

class Upload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '',
            loading: false
        };
    }

    getBase64 = (file, callback) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(file);
    };

    handleChange = info => {
        if (info.file.status === uploadConstants.FILE_STATUS_UPLOADING) {
            this.setState({ loading: true });

            return;
        }

        if (info.file.status === uploadConstants.FILE_STATUS_DONE) {
            this.getBase64(info.file.originFileObj, url =>
                this.setState({ url, loading: false })
            );
        }
    };

    beforeUpload = file => {
        const isValidType = this.props.allowedTypes.includes(file.type);

        if (!isValidType) {
            message.error(this.props.translate('validation.file.type'));
        }

        const isValidSize = file.size / uploadConstants.SIZE_DIVIDER < this.props.allowedSize;

        if (!isValidSize) {
            message.error(this.props.translate('validation.file.size'));
        }

        return isValidType && isValidSize;
    };

    handleRequest = ({ onSuccess, onError, file, headers }) => {
        this.props.upload(file, headers).then(
            files => {
                this.props.setFormField(files);

                onSuccess(file);
            },
            errors => {
                onError(errors);
            }
        );
    };

    render() {
        const { name, listType, className, showUploadList, initialValue } = this.props;
        const { url, loading } = this.state;

        return (
            <AntUpload
                name={name}
                customRequest={this.handleRequest}
                listType={listType}
                className={className}
                showUploadList={showUploadList}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
            >
                {this.props.displayContent && this.props.displayContent(initialValue ? initialValue : url, loading)}
            </AntUpload>
        );
    }
}

Upload.defaultProps = {
    allowedTypes: [],
    allowedSize: 5,
    name: 'file',
    listType: 'text',
    showUploadList: false
};

Upload.propTypes = {
    translate: PropTypes.func,
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    listType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    allowedTypes: PropTypes.array.isRequired,
    allowedSize: PropTypes.number.isRequired,
    displayContent: PropTypes.func,
    upload: PropTypes.func,
    setFormField: PropTypes.func,
    className: PropTypes.string,
    showUploadList: PropTypes.bool
};

export default Upload;
