import axios from 'axios';

const table = body => {
    return axios.post('/client-tables', body).then(
        response => {
            const { table } = response.data;

            return table;
        },
        error => {
            const { response } = error;
            const { data } = response;
            const { errors } = data;

            return Promise.reject(errors);
        }
    );
};

export const clientServices = {
    table
};
