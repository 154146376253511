import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Row } from 'antd';

import { formHelper } from 'dood-react-components';

import LoginForm from '../../components/Form/LoginForm';

import { authenticationActions } from '../../actions';
import { createLoadingSelector, translator } from '../../helpers';
import { loadingConstants } from '../../constants/loading.constants';

import logo from './logo.svg';

class Login extends Component {
    static fields = [
        'username',
        'password'
    ];

    constructor(props) {
        super(props);

        this.state = formHelper.createStateFromFields(Login.fields);
    }

    login = () => {
        const { username, password } = this.state;

        this.props.login(username.value, password.value);
    };

    handleInputChange = changedFields => {
        this.setState(({ fields }) => ({ ...fields, ...changedFields }));
    };

    render() {
        const { loggedIn, error } = this.props.authentication;

        if (loggedIn) {
            return (<Redirect to='/' />);
        }

        return (
            <div>
                <Row type='flex' justify='center' align='middle'>
                    <img src={logo} alt='login' style={{ margin: '40px', width: '200px' }} />
                </Row>

                <Row type='flex' justify='center' align='middle'>
                    <LoginForm
                        username={this.state.username}
                        password={this.state.password}
                        translate={this.props.translate}
                        error={error}
                        onSubmit={this.login}
                        onInputChange={this.handleInputChange}
                        loading={this.props.loading}
                        fields={Login.fields}
                    />
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        translate: translator(state),
        loading: createLoadingSelector([
            loadingConstants.AUTHENTICATION_LOGIN
        ])(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(authenticationActions.login(username, password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
