import { dashboardConstants } from '../constants/dashboard.constants';
import { statsService } from '../services';

const dashboard = (start, end) => {
    const request = () => ({
        type: dashboardConstants.DASHBOARD_STATS_REQUEST
    });

    const success = () => ({
        type: dashboardConstants.DASHBOARD_STATS_SUCCESS
    });

    const failure = () => ({
        type: dashboardConstants.DASHBOARD_STATS_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return statsService.dashboard(start, end).then(
            stats => {
                dispatch(success());

                return stats;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

export const statsActions = {
    dashboard
};
