import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from 'antd';
import { Table as DoodTable, TableContainer } from 'dood-react-components';

import { translator } from '../../helpers';
import { categoryActions } from '../../actions';
import { tableConstants } from '../../constants/table.constants';

const { confirm } = Modal;

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: null,
            loading: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = data => {
        this.props.dispatch(categoryActions.table(data)).then(
            result => {
                this.setState({
                    categories: result,
                    pagination: result.pagination,
                    filters: result.filters,
                    loading: false
                });
            },
            error => {
                this.setState({ error });
            }
        );
    };

    handleStatusChanged = (id, isActive) => {
        this.props.dispatch(categoryActions.edit(id, { isActive })).then(
            category => {
                const index = this.state.categories.data.map(item => {
                    return item.id;
                }).indexOf(category.id);

                if (index < 0) {
                    return;
                }

                this.setState(previousState => {
                    let newData = [...previousState.categories.data];

                    const isActive = category.isActive | 0;
                    const oldActionName = isActive
                        ? tableConstants.ACTION_ACTIVATE
                        : tableConstants.ACTION_DEACTIVATE;
                    const newActionName = isActive
                        ? tableConstants.ACTION_DEACTIVATE
                        : tableConstants.ACTION_ACTIVATE;

                    let actions = {};

                    Object.keys(newData[index].actions).forEach(item => {
                        if (item === oldActionName) {
                            actions[newActionName] = newData[index].actions[oldActionName];
                            actions[newActionName].icon = isActive
                                ? tableConstants.ACTION_DEACTIVATE_ICON
                                : tableConstants.ACTION_ACTIVATE_ICON;

                            delete actions[oldActionName];
                        } else {
                            actions[item] = newData[index].actions[item];
                        }
                    });

                    Object.assign(newData[index], { isActive, actions });

                    return {
                        categories: { ...this.state.categories, data: newData }
                    };
                });
            },
            error => {
                this.setState({ error });
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({ loading: true });

        this.loadData({
            ...this.state.categories.options,
            pagination: { ...pagination, current: pagination.current },
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
            filters: filters
        });
    };

    handleActionClick = (action, rowData) => {
        switch (action) {
            case tableConstants.ACTION_REMOVE:
                confirm({
                    title: this.props.translate('modal.title.are_you_sure'),
                    okText: this.props.translate('label.yes'),
                    okType: 'danger',
                    cancelText: this.props.translate('label.no'),
                    onOk: () => {
                        return this.handleDelete(rowData.id);
                    }
                });
                break;

            case tableConstants.ACTION_ACTIVATE:
            case tableConstants.ACTION_DEACTIVATE:
                this.handleStatusChanged(rowData.id, !rowData.isActive);
                break;

            default:
                break;
        }
    };

    handleDelete = id => {
        return new Promise((resolve, reject) => {
            this.props.dispatch(categoryActions.remove(id)).then(
                () => {
                    const data = this.state.categories.data.filter(category => category.id !== id);

                    this.setState(previousState => ({
                        categories: { ...previousState.categories, data }
                    }));

                    resolve();
                },
                error => {
                    this.setState({ error });

                    reject();
                }
            );
        });
    };

    render() {
        const { categories, loading } = this.state;

        let tableComponent = null;

        if (categories) {
            const { data, columns, filters, pagination } = categories;

            tableComponent = (
                <DoodTable
                    translate={this.props.translate}
                    loading={loading}
                    data={data}
                    columns={columns}
                    filters={filters}
                    pagination={pagination}
                    onActionClick={this.handleActionClick}
                    onTableChange={this.handleTableChange}
                />
            );
        }

        return (
            <TableContainer
                translate={this.props.translate}
                title={this.props.translate('title.categories')}
                tableComponent={tableComponent}
                error={this.state.error}
                createRoute='/categories/create'
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: translator(state)
    };
};

export default connect(mapStateToProps)(Table);
