import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { menu } from './menu.reducer';
import { loading } from './loading.reducer';
import { translations } from './translations.reducer';

const rootReducer = combineReducers({
    loading,
    authentication,
    menu,
    translations
});

export default rootReducer;
