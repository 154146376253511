import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormContainer, formHelper } from 'dood-react-components';

import ProductForm from '../../components/Form/ProductForm';

import { createLoadingSelector, translator } from '../../helpers';
import { productActions, categoryActions, currencyActions, providerActions } from '../../actions';
import { loadingConstants } from '../../constants/loading.constants';
import { productConstants } from '../../constants/product.constants';
import { currencyConstants } from '../../constants/currency.constants';

class Form extends Component {
    static fields = [
        'providerId',
        'categoryId',
        'titleRo',
        'descriptionRo',
        'titleEn',
        'descriptionEn',
        'titleHu',
        'descriptionHu',
        'price',
        'currencyId',
        'mobileDistributionUid',
        'displayOrder',
        'isActive'
    ];

    constructor(props) {
        super(props);

        this.state = {
            product: formHelper.createStateFromFields(Form.fields, {
                currencyId: currencyConstants.ID_EUR,
                displayOrder: productConstants.MIN_DISPLAY_ORDER,
                isActive: true
            }),
            currencies: [],
            providers: [],
            categories: [],
            error: ''
        };
    }

    componentDidMount() {
        this.props.getCurrencies().then(
            currencies => {
                this.setState({ currencies });
            }
        );

        this.props.getProviders().then(
            providers => {
                this.setState({ providers });
            }
        );

        if (this.props.isEdit) {
            this.props.find(this.props.match.params.id).then(
                response => {
                    const product = formHelper.mapValuesToFields(response, Form.fields);

                    this.setState({ product }, () => {
                        this.loadCategories(response.providerId);
                    });
                }
            );
        }
    }

    loadCategories = providerId => {
        if (providerId) {
            this.props.getCategoriesByProviderId(providerId).then(
                categories => {
                    this.setState({ categories });
                }
            );
        } else {
            this.setState({ categories: [] });
        }
    };

    handleSubmit = () => {
        const { product } = this.state;

        let data = {};

        Form.fields.forEach(field => {
            data[field] = product[field].value;

            // clear form errors
            delete product[field].errors;
        });

        this.setState({ product }, () => {
            this.props.isEdit
                ? this.handleEdit(data)
                : this.handleCreate(data);
        });
    };

    handleCreate = data => {
        this.props.create(data).then(
            () => {
                this.props.history.push('/products');
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleEdit = data => {
        this.props.edit(this.props.match.params.id, data).then(
            () => {
                this.props.history.push('/products');
            },
            errors => {
                this.handleErrors(errors);
            }
        );
    };

    handleCancel = () => {
        this.props.history.push('/products');
    };

    handleInputChange = changedFields => {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                ...changedFields
            }
        }));
    };

    handleProviderChange = providerId => {
        this.setState(prevState => ({
            ...prevState,
            product: {
                ...prevState.product,
                categoryId: {
                    value: undefined
                }
            }
        }), () => {
            this.loadCategories(providerId);
        });
    };

    handleErrors = errors => {
        const { product }  = this.state;

        const { error, object } = formHelper.mapErrorsToFields(product, errors);

        this.setState({ product: object, error });
    };

    render() {
        const formComponent = (
            <ProductForm
                translate={this.props.translate}
                product={this.state.product}
                providers={this.state.providers}
                categories={this.state.categories}
                currencies={this.state.currencies}
                onInputChange={this.handleInputChange}
                onProviderChange={this.handleProviderChange}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                error={this.state.error}
                fields={Form.fields}
            />
        );

        return (
            <FormContainer
                title={this.props.translate(this.props.isEdit ? 'title.edit_product' : 'title.create_product')}
                loading={this.props.loading}
                formComponent={formComponent}
            />
        );
    }
}

Form.propTypes = {
    isEdit: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        translate: translator(state),
        loading: createLoadingSelector([
            loadingConstants.GET_CURRENCIES,
            loadingConstants.GET_PROVIDERS,
            loadingConstants.PRODUCT_CREATE,
            loadingConstants.PRODUCT_EDIT,
            loadingConstants.PRODUCT_FIND
        ])(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrencies: () => dispatch(currencyActions.get()),
        getProviders: () => dispatch(providerActions.get()),
        getCategoriesByProviderId: providerId => dispatch(categoryActions.getByProviderId(providerId)),
        find: id => dispatch(productActions.find(id)),
        create: product => dispatch(productActions.create(product)),
        edit: (id, product) => dispatch(productActions.edit(id, product))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
