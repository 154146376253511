import { exchangeRateServices } from '../services';
import { exchangeRateConstants } from '../constants/exchange-rate.constants';

const table = body => {
    return async dispatch => {
        return exchangeRateServices.table(body).then(
            table => {
                return table;
            },
            error => {
                return error;
            }
        );
    };
};

const create = body => {
    const request = () => ({
        type: exchangeRateConstants.EXCHANGE_RATE_CREATE_REQUEST
    });

    const success = () => ({
        type: exchangeRateConstants.EXCHANGE_RATE_CREATE_SUCCESS
    });

    const failure = () => ({
        type: exchangeRateConstants.EXCHANGE_RATE_CREATE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        exchangeRateServices.create(body).then(
            exchangeRate => {
                dispatch(success());

                resolve(exchangeRate);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const remove = id => {
    const request = () => ({
        type: exchangeRateConstants.EXCHANGE_RATE_REMOVE_REQUEST
    });

    const success = () => ({
        type: exchangeRateConstants.EXCHANGE_RATE_REMOVE_SUCCESS
    });

    const failure = () => ({
        type: exchangeRateConstants.EXCHANGE_RATE_REMOVE_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return exchangeRateServices.remove(id).then(
            () => {
                dispatch(success());
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

export const exchangeRateActions = {
    table,
    create,
    remove
};
