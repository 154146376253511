import { categoryService } from '../services';
import { categoryConstants } from '../constants/category.constants';

const table = body => {
    return async dispatch => {
        return categoryService.table(body).then(
            table => {
                return table;
            },
            error => {
                return error;
            }
        );
    };
};

const create = body => {
    const request = () => ({
        type: categoryConstants.CATEGORY_CREATE_REQUEST
    });

    const success = () => ({
        type: categoryConstants.CATEGORY_CREATE_SUCCESS
    });

    const failure = () => ({
        type: categoryConstants.CATEGORY_CREATE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        categoryService.create(body).then(
            category => {
                dispatch(success());

                resolve(category);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const edit = (id, body) => {
    const request = () => ({
        type: categoryConstants.CATEGORY_EDIT_REQUEST
    });

    const success = () => ({
        type: categoryConstants.CATEGORY_EDIT_SUCCESS
    });

    const failure = () => ({
        type: categoryConstants.CATEGORY_EDIT_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        categoryService.edit(id, body).then(
            category => {
                dispatch(success());

                resolve(category);
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const find = id => {
    const request = () => ({
        type: categoryConstants.CATEGORY_FIND_REQUEST
    });

    const success = () => ({
        type: categoryConstants.CATEGORY_FIND_SUCCESS
    });

    const failure = () => ({
        type: categoryConstants.CATEGORY_FIND_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return categoryService.find(id).then(
            category => {
                dispatch(success());

                return category;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

const remove = id => {
    const request = () => ({
        type: categoryConstants.CATEGORY_REMOVE_REQUEST
    });

    const success = () => ({
        type: categoryConstants.CATEGORY_REMOVE_SUCCESS
    });

    const failure = () => ({
        type: categoryConstants.CATEGORY_REMOVE_FAILURE
    });

    return dispatch => new Promise((resolve, reject) => {
        dispatch(request());

        categoryService.remove(id).then(
            () => {
                dispatch(success());

                resolve();
            },
            errors => {
                dispatch(failure());

                reject(errors);
            }
        );
    });
};

const getByProviderId = providerId => {
    const request = () => ({
        type: categoryConstants.CATEGORIES_GET_BY_PROVIDER_ID_REQUEST
    });

    const success = () => ({
        type: categoryConstants.CATEGORIES_GET_BY_PROVIDER_ID_SUCCESS
    });

    const failure = () => ({
        type: categoryConstants.CATEGORIES_GET_BY_PROVIDER_ID_FAILURE
    });

    return async dispatch => {
        dispatch(request());

        return categoryService.getByProviderId(providerId).then(
            categories => {
                dispatch(success());

                return categories;
            },
            error => {
                dispatch(failure());

                return error;
            }
        );
    };
};

export const categoryActions = {
    table,
    create,
    edit,
    find,
    remove,
    getByProviderId
};
