export const postConstants = {
    POST_CREATE_REQUEST: 'POST_CREATE_REQUEST',
    POST_CREATE_SUCCESS: 'POST_CREATE_SUCCESS',
    POST_CREATE_FAILURE: 'POST_CREATE_FAILURE',

    POST_EDIT_REQUEST: 'POST_EDIT_REQUEST',
    POST_EDIT_SUCCESS: 'POST_EDIT_SUCCESS',
    POST_EDIT_FAILURE: 'POST_EDIT_FAILURE',

    POST_FIND_REQUEST: 'POST_FIND_REQUEST',
    POST_FIND_SUCCESS: 'POST_FIND_SUCCESS',
    POST_FIND_FAILURE: 'POST_FIND_FAILURE',

    POST_REMOVE_REQUEST: 'POST_REMOVE_REQUEST',
    POST_REMOVE_SUCCESS: 'POST_REMOVE_SUCCESS',
    POST_REMOVE_FAILURE: 'POST_REMOVE_FAILURE',

    UPLOAD_PATH: '/posts/upload'
};

