import React from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    Divider,
    Alert,
    Input,
    InputNumber,
    Checkbox,
    Select,
    Slider,
    Button
} from 'antd';

import { formHelper, styles } from 'dood-react-components';

import { productConstants } from '../../constants/product.constants';

const { Item: FormItem, create } = Form;
const { TextArea } = Input;

const ProductForm = create({
    name: 'product',
    onFieldsChange(props, changedFields) {
        props.onInputChange(changedFields);
    },
    mapPropsToFields(props) {
        return formHelper.mapPropsToFields(props.product, props.fields);
    }
})(({form, translate, providers, categories, currencies, onSubmit, onCancel, onProviderChange, error }) => {
    const { getFieldDecorator, getFieldValue } = form;
    const { formItemLayout, buttonsLayout } = styles;

    const handleSubmit = event => {
        event.preventDefault();

        const { validateFields } = form;

        validateFields(errors => {
            if (!errors) {
                onSubmit();
            }
        });
    };

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            {error && <Alert message={error} type='error' />}

            <FormItem label={translate('label.provider')}>
                {getFieldDecorator('providerId')(
                    <Select placeholder={translate('label.provider')} onChange={onProviderChange}>
                        {formHelper.renderOptions(providers)}
                    </Select>
                )}
            </FormItem>

            <FormItem label={translate('label.category')}>
                {getFieldDecorator('categoryId', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.category')
                    }]
                })(
                    <Select placeholder={translate('label.category')}>
                        {formHelper.renderOptions(categories, 'id', 'titleRo')}
                    </Select>
                )}
            </FormItem>

            <Divider>{translate('label.romanian')}</Divider>

            <FormItem label={translate('label.title')}>
                {getFieldDecorator('titleRo', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.title')
                    }]
                })(
                    <Input placeholder={translate('label.title')} />
                )}
            </FormItem>

            <FormItem label={translate('label.description')}>
                {getFieldDecorator('descriptionRo', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.description')
                    }]
                })(
                    <TextArea placeholder={translate('label.description')} />
                )}
            </FormItem>

            <Divider>{translate('label.english')}</Divider>

            <FormItem label={translate('label.title')}>
                {getFieldDecorator('titleEn', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.title')
                    }]
                })(
                    <Input placeholder={translate('label.title')} />
                )}
            </FormItem>

            <FormItem label={translate('label.description')}>
                {getFieldDecorator('descriptionEn', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.description')
                    }]
                })(
                    <TextArea placeholder={translate('label.description')} />
                )}
            </FormItem>

            <Divider>{translate('label.hungarian')}</Divider>

            <FormItem label={translate('label.title')}>
                {getFieldDecorator('titleHu', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.title')
                    }]
                })(
                    <Input placeholder={translate('label.title')} />
                )}
            </FormItem>

            <FormItem label={translate('label.description')}>
                {getFieldDecorator('descriptionHu', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.description')
                    }]
                })(
                    <TextArea placeholder={translate('label.description')} />
                )}
            </FormItem>

            <Divider />

            <FormItem label={translate('label.display_order')}>
                {getFieldDecorator('displayOrder', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.display_order')
                    }]
                })(
                    <Slider min={productConstants.MIN_DISPLAY_ORDER} max={productConstants.MAX_DISPLAY_ORDER} />
                )}
            </FormItem>

            <FormItem label={translate('label.active')}>
                {getFieldDecorator('isActive')(
                    <Checkbox defaultChecked={getFieldValue('isActive')} />
                )}
            </FormItem>

            <FormItem label={translate('label.price')}>
                <FormItem style={{ display: 'inline-block', width: '50%' }}>
                    {getFieldDecorator('price', {
                        rules: [{
                            required: true,
                            message: translate('validation.required.price')
                        }]
                    })(
                        <InputNumber placeholder={translate('label.price')} />
                    )}
                </FormItem>

                <FormItem style={{ display: 'inline-block', width: '50%' }}>
                    {getFieldDecorator('currencyId', {
                        rules: [{
                            required: true,
                            message: translate('validation.required.currency')
                        }]
                    })(
                        <Select placeholder={translate('label.category')}>
                            {formHelper.renderOptions(currencies)}
                        </Select>
                    )}
                </FormItem>
            </FormItem>

            <FormItem label={translate('label.mobile_distribution_uid')}>
                {getFieldDecorator('mobileDistributionUid', {
                    rules: [{
                        required: true,
                        message: translate('validation.required.mobile_distribution_uid')
                    }]
                })(
                    <Input placeholder={translate('label.mobile_distribution_uid')} />
                )}
            </FormItem>

            <FormItem {...buttonsLayout}>
                <Button type='primary' htmlType='submit'>
                    {translate('button.save')}
                </Button>

                <Button htmlType='button' style={{ marginLeft: 15 }} onClick={onCancel}>
                    {translate('button.cancel')}
                </Button>
            </FormItem>
        </Form>
    );
});

ProductForm.propTypes = {
    ...formHelper.propTypes,
    translate: PropTypes.func.isRequired,
    product: PropTypes.shape({
        providerId: PropTypes.shape({
            value: PropTypes.number
        }),
        categoryId: PropTypes.shape({
            value: PropTypes.number
        }),
        titleRo: PropTypes.shape({
            value: PropTypes.string
        }),
        descriptionRo: PropTypes.shape({
            value: PropTypes.string
        }),
        titleEn: PropTypes.shape({
            value: PropTypes.string
        }),
        descriptionEn: PropTypes.shape({
            value: PropTypes.string
        }),
        titleHu: PropTypes.shape({
            value: PropTypes.string
        }),
        descriptionHu: PropTypes.shape({
            value: PropTypes.string
        }),
        price: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ])
        }),
        currencyId: PropTypes.shape({
            value: PropTypes.number
        }),
        mobileDistributionUid: PropTypes.shape({
            value: PropTypes.string
        }),
        displayOrder: PropTypes.shape({
            value: PropTypes.number
        }),
        isActive: PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.number
            ])
        })
    }),
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onProviderChange: PropTypes.func.isRequired,
    providers: PropTypes.array,
    categories: PropTypes.array,
    currencies: PropTypes.array
};

ProductForm.defaultProps = {
    ...formHelper.defaultProps,
    providers: [],
    categories: [],
    currencies: []
};

export default ProductForm;
