import { translationsConstants } from '../constants/translations.constants';

const initialState = { translations: {} };

export const translations = (state = initialState, action) => {
    switch (action.type) {
        case translationsConstants.FETCH_TRANSLATIONS_SUCCESS:
            return action.translations;

        case translationsConstants.FETCH_TRANSLATIONS_FAILURE:
            return {
                error: action.error
            };

        default:
            return state;
    }
};
