import axios from 'axios';

const getTranslations = () => {
    return axios
        .get('/translations/en')  // TODO: Replace 'en' with browser locale
        .then(
            (response) => {
                const { translations } = response.data;

                return translations;
            },
            error => {
                const { response } = error;
                const { data } = response;
                const { errors } = data;

                return Promise.reject(errors);
            }
        );
};

export const translationsService = {
    getTranslations
};
